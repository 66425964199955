import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Center,
  Flex,
  Grid,
  HStack,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import {
  alphabet,
  gradients,
  gradientToCssGradientProp,
  truncateNumber,
  WheelAuction,
  WheelPointsOutcome,
} from '@elan-twitch/shared'
import { useFirebase } from '@elanmc/elan-react'
import { a, useSpring } from '@react-spring/web'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import {
  AddButton, ExpandButton, LoadButton, SaveButton,
} from 'components/shared/Buttons'
import { useWithLoading } from 'hooks/useWithLoading'
import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react'
import { useScreen } from 'store/useScreen'
import { ShadowText } from '../../shared/ShadowText'
import { TimeElapsed } from '../../shared/TimeElapsed'
import { useWheelContext } from './context'
import { usePalette } from './hooks'
import { ReopenBidding } from './ReopenBidding'
import {
  endWheelBidding,
  finishWheelAuction,
  reopenWheelAuctionBidding,
  reportSpinRested,
  saveWheelAuction,
  setCurrentAuctionId,
  spinWheelAuction,
  unsaveWheelAuction,
} from './utils'
import { WheelSpinner } from './WheelSpinner'

const TopBidView = ({ points, user_name }: WheelAuction['outcomes'][number]['topBids'][number]) => (
  <Center flex={1} gap={1} bg='blackAlpha.500' borderRadius={6} p={1} px={3}>
    <Text whiteSpace='nowrap' filter='drop-shadow(1px 1px 2px black)' color='white' fontSize='sm' isTruncated flex={1}>
      {user_name}
    </Text>
    <Text
      whiteSpace='nowrap'
      filter='drop-shadow(1px 1px 2px black)'
      bg={gradientToCssGradientProp(gradients.gold)}
      color='white'
      fontSize='xs'
      borderRadius={8}
      px={1}
      textShadow='1px 1px 3px black'
    >
      {truncateNumber(points)}
    </Text>
  </Center>
)

const WheelAuctionVotingOutcome = ({
  outcome,
  totalPoints,
  auction,
  basePoints,
  width,
  index,
}: {
  outcome: WheelPointsOutcome
  auction: WheelAuction
  width: number
  totalPoints: number
  basePoints: number
  index: number
}) => {
  const { broadcaster } = useContext(BroadcasterContext)
  const percentRef = useRef<HTMLSpanElement>(null)
  const initText = useRef(`${(totalPoints ? ((outcome.points + basePoints) / totalPoints) * 100 : 0).toFixed(
    1,
  )}%`)
  useEffect(() => {
    if (!percentRef.current) return
    percentRef.current.innerText = initText.current
  }, [])

  const { progress } = useSpring({
    progress: totalPoints ? ((outcome.points + basePoints) / totalPoints) * 100 : 0,
    onChange: () => {
      if (!percentRef.current) return
      percentRef.current.innerText = `${progress.get().toFixed(1)}%`
    },
  })

  const { pointsBankAccountConfig } = broadcaster || {}
  const { pointIconPath } = pointsBankAccountConfig || {}

  const palette = usePalette(auction)

  return (
    <Center width='100%' height='100%' p={2}>
      <Flex
        flexFlow='column'
        py={2}
        px={3}
        gap={1}
        w={`${width}px`}
        bg={
          auction.winningOutcomeIndex === index && auction.spin_rested_at
            ? gradientToCssGradientProp(gradients.gold)
            : 'linear-gradient(60deg, #29323c 0%, #485563 100%)'
        }
        backgroundBlendMode='multiply,multiply'
        boxShadow='1px 1px 8px rgba(0,0,0,0.8)'
        borderRadius={6}
      >
        <HStack px={1} w='100%' justify='flex-start'>
          <Text flex={1} filter='drop-shadow(1px 1px 2px black)' fontSize='lg' color='white'>
            {alphabet[index].toUpperCase()} - {outcome.title}
          </Text>
          <Center ml='auto' bg='blackAlpha.500' borderRadius='20px' w='60px' h='24px'>
            <span
              ref={percentRef}
              style={{
                color: '#ffffff',
                fontFamily: 'Encode Sans',
                filter: 'drop-shadow(1px 1px 2px black)',
                whiteSpace: 'nowrap',
                fontSize: '0.9rem',
              }}
            />
          </Center>
        </HStack>
        <div
          style={{
            width: '100%',
            height: '50px',
            boxShadow: 'inset 0 0 10px rgba(0,0,0,0.5)',
            background: '#00000066',
            position: 'relative',
            borderRadius: 'inherit',
            display: 'flex',
            padding: '6px',
          }}
        >
          <a.div
            style={{
              width: progress.to((p) => `${p}%`),
              height: '100%',
              boxShadow: '1px 1px 5px rgba(0,0,0,0.5)',
              background: palette[index % palette.length],
              borderRadius: 'inherit',
              position: 'relative',
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: '13px',
              left: '14px',
              color: '#ffffff',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontFamily: 'Encode Sans',
              filter: 'drop-shadow(1px 1px 2px black)',
              whiteSpace: 'nowrap',
              fontSize: '1rem',
            }}
          >
            <Flex>
              <span>
                {basePoints} + {outcome.points}
              </span>
              {pointIconPath ? <Image position='relative' src={pointIconPath} w='1.5rem' ml={1} h='1.5rem' /> : null}
            </Flex>
          </div>
        </div>
        <VStack align='flex-start' w='100%' justify='space-between' px={1}>
          <HStack justify='space-between' w='100%' pt={1} spacing={0} align='flex-start'>
            <ShadowText
              style={{
                fontSize: '1.2rem',
                lineHeight: 1,
                whiteSpace: 'nowrap',
              }}
            >
              {`!wheelbid ${alphabet[index]} `}
              <span
                style={{
                  marginLeft: '0.2rem',
                  fontSize: '1.2rem',
                  lineHeight: 1,
                  position: 'relative',
                  color: '#00ffaa',
                }}
              >
                amount
              </span>
            </ShadowText>
            <Text
              fontSize='sm'
              lineHeight={1}
              whiteSpace='nowrap'
              filter='drop-shadow(1px 1px 2px black)'
              color='white'
            >
              {outcome.numBids} BIDS
            </Text>
          </HStack>
          <Center w='100%'>
            <Popover isLazy trigger='hover'>
              <PopoverTrigger>
                <VStack cursor='pointer' w='100%' align='center' spacing={0}>
                  <Text fontSize='xs' color='whiteAlpha.700'>
                    TOP BIDS
                  </Text>
                  <HStack w='100%'>
                    {outcome.topBids.length ? (
                      outcome.topBids
                        .slice(0, 2)
                        .map((bid, idx) => <TopBidView key={`top-bid-preview-${idx}`} {...bid} />)
                    ) : (
                      <Text filter='drop-shadow(1px 1px 2px black)'>No bids yet</Text>
                    )}
                  </HStack>
                </VStack>
              </PopoverTrigger>
              <PopoverContent maxW='300px' w='auto' bg='gray.700'>
                <PopoverBody p={1}>
                  <VStack justify='center' align='center' spacing={0}>
                    <Flex justify='center' flexFlow='row wrap'>
                      {outcome.topBids.length ? (
                        outcome.topBids.map((bid, idx) => (
                          <Center key={`top-bid-view-${idx}`} p={1}>
                            <TopBidView {...bid} />
                          </Center>
                        ))
                      ) : (
                        <Text px={2} filter='drop-shadow(1px 1px 2px black)'>
                          No bids yet
                        </Text>
                      )}
                    </Flex>
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Center>
        </VStack>
      </Flex>
    </Center>
  )
}

export const WheelAuctionVotingStage = ({ readOnly }: { readOnly?: boolean }) => {
  const { auction, broadcaster, setEditMode } = useWheelContext()
  const totalPoints = useMemo(
    () => auction?.outcomes.reduce((acc, o) => acc + auction.outcomeBasePoints + o.points, 0) || 0,
    [auction],
  )
  const { db } = useFirebase()
  const [isLoading, setIsLoading] = useState(false)

  const onSpin = useCallback(() => {
    if (!auction) return
    setIsLoading(true)
    spinWheelAuction(db, broadcaster, auction)
      .then(() => undefined)
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }, [auction, broadcaster, db])

  const onEndAuction = useCallback(() => {
    if (!auction) return
    setIsLoading(true)
    endWheelBidding(db, broadcaster, auction._id)
      .then(() => undefined)
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }, [auction, broadcaster, db])

  const spinning = useMemo(() => auction?.spun_at && !auction.spin_rested_at, [auction])
  const toast = useToast()
  const { call: onSave, loading: saveLoading } = useWithLoading(saveWheelAuction)
  const { call: onUnsave, loading: unsaveLoading } = useWithLoading(unsaveWheelAuction)
  const screenWidth = useScreen((s) => s.width)
  const screenHeight = useScreen((s) => s.height)
  const { bidsPanelWidth, wheelPanelWidth, gridWidth } = useMemo(() => {
    const wheelWidth = spinning ? Math.min(screenHeight, screenWidth) : screenWidth / 2
    const bidsWidth = spinning ? 0 : screenWidth - wheelWidth
    const gWidth = bidsWidth - 30
    return {
      bidsPanelWidth: bidsWidth,
      wheelPanelWidth: wheelWidth,
      gridWidth: gWidth,
    }
  }, [screenWidth, screenHeight, spinning])

  const numCols = useMemo(() => Math.floor(gridWidth / 250), [gridWidth])
  const colWidth = useMemo(() => gridWidth / numCols - 2, [gridWidth, numCols])

  const [confirmingBackId, setConfirmingBackId] = useState<string | null>(null)
  const handleBack = useCallback(
    (exitMode?: 'load' | 'create') => {
      if (exitMode) setEditMode(exitMode)
      if (auction && !auction?.saved_on) {
        setConfirmingBackId(auction._id)
        return
      }
      setCurrentAuctionId(db, broadcaster, null)
    },
    [broadcaster, auction, setEditMode, db],
  )

  const [confirmingBack, setConfirmingBack] = useState(false)
  const handleConfirmBack = useCallback(
    async (withSave: boolean) => {
      setConfirmingBack(true)
      setConfirmingBackId(null)
      if (confirmingBack) {
        if (withSave) await saveWheelAuction(db, broadcaster, auction?._id || '')
        await finishWheelAuction(db, broadcaster, auction?._id || '')
      }
      await setCurrentAuctionId(db, broadcaster, null)
      setConfirmingBack(false)
    },
    [broadcaster, auction, confirmingBack, db],
  )

  const handleReportSpinRested = useCallback(() => {
    if (!auction) return
    reportSpinRested(db, broadcaster, auction._id)
      .then(() => undefined)
      .catch(() => {
        toast({
          title: 'Error reporting spin rested',
          status: 'error',
          duration: 5000,
        })
      })
  }, [auction, broadcaster, toast, db])

  const handleReopen = useCallback(
    async (discardBids: boolean) => {
      if (!auction) return undefined
      setIsLoading(true)
      return reopenWheelAuctionBidding(db, broadcaster, auction._id, discardBids)
        .then(() => undefined)
        .catch(console.error)
        .finally(() => setIsLoading(false))
    },
    [auction, broadcaster, db],
  )
  const cancelRef = useRef<HTMLButtonElement>(null)
  return (
    <VStack
      spacing={3}
      p={4}
      pointerEvents={auction ? 'auto' : 'none'}
      borderRadius={4}
      opacity={auction ? 1 : 0}
      transition='opacity 400ms'
      w={`${screenWidth}px`}
      h={`${screenHeight}px`}
    >
      <HStack h='100%' spacing={0} flex={1}>
        <VStack
          width={`${bidsPanelWidth}px`}
          overflow='hidden'
          p={2}
          px={3}
          maxH='90%'
          transition={`width 400ms ease ${bidsPanelWidth ? '0ms' : '500ms'}, opacity 400ms ease ${
            bidsPanelWidth ? '500ms' : '0ms'
          }`}
          opacity={bidsPanelWidth ? 1 : 0}
          style={{ overflow: 'hidden' }}
        >
          <HStack
            py={2}
            px={3}
            gap={1}
            w='100%'
            bg={'linear-gradient(60deg, #29323c 0%, #485563 100%)'}
            backgroundBlendMode='multiply,multiply'
            boxShadow='1px 1px 8px rgba(0,0,0,0.8)'
            borderRadius={6}
          >
            <ShadowText style={{ fontFamily: 'Encode Sans', fontSize: '1.2rem', flex: 1 }}>
              {auction?.title || ''}
            </ShadowText>
            {!readOnly ? (
              <HStack spacing={0} ml='auto'>
                <ExpandButton
                  active={!!(auction?.started_at && !auction.ended_at)}
                  onClick={onEndAuction}
                  isLoading={isLoading}
                  width={120}
                >
                  END BIDDING
                </ExpandButton>
                <ReopenBidding isLoading={isLoading} onReopen={handleReopen} />
                <ExpandButton
                  active={!spinning && !!auction?.started_at}
                  onClick={onSpin}
                  isLoading={isLoading}
                  width={80}
                >
                  {auction?.spun_at ? 'RE-SPIN' : 'SPIN'}
                </ExpandButton>
              </HStack>
            ) : null}
          </HStack>
          <Grid
            transition='all 400ms'
            width={'100%'}
            templateColumns={`repeat(${numCols}, ${colWidth}px)`}
            overflowY='auto'
            overflowX='hidden'
            flexFlow='row wrap'
            justifyContent='center'
            flex={1}
          >
            {auction?.outcomes.map((outcome, index) => (
              <WheelAuctionVotingOutcome
                key={outcome.title}
                width={colWidth}
                auction={auction}
                basePoints={auction.outcomeBasePoints}
                index={index}
                outcome={outcome}
                totalPoints={totalPoints}
              />
            )) || null}
          </Grid>
          <HStack
            w='100%'
            px={3}
            py={2}
            gap={1}
            height={auction?.ended_at ? '40px' : '0px'}
            align='center'
            minW='400px'
            opacity={auction?.ended_at ? 1 : 0}
            overflow='hidden'
            transition='all 400ms'
            bg={'linear-gradient(60deg, #29323c 0%, #485563 100%)'}
            backgroundBlendMode='multiply,multiply'
            boxShadow='1px 1px 8px rgba(0,0,0,0.8)'
            borderRadius={6}
          >
            <ShadowText
              style={{
                fontFamily: 'Encode Sans',
                fontSize: '1rem',
                flex: 1,
              }}
            >
              Bidding ended <TimeElapsed since={auction?.ended_at} /> ago
            </ShadowText>
          </HStack>
        </VStack>
        <Center transition='width 500ms' width={`${wheelPanelWidth}px`}>
          <WheelSpinner
            onSpinComplete={readOnly ? undefined : () => handleReportSpinRested()}
            size={wheelPanelWidth * 0.9}
          />
        </Center>
      </HStack>
      {readOnly ? null : (
        <HStack spacing={0} pointerEvents='auto' position='absolute' right={8} top={4}>
          <AddButton isLoading={isLoading} onClick={() => handleBack('create')} label='NEW WHEEL' />
          <LoadButton isLoading={isLoading} onClick={() => handleBack('load')} label='LOAD WHEEL' />

          <SaveButton
            // variant='ghost'
            savedOn={auction?.saved_on}
            onClick={() => (auction?.saved_on ? onUnsave(db, broadcaster, auction?._id || '') : onSave(db, broadcaster, auction?._id || ''))
            }
            isLoading={saveLoading || unsaveLoading}
          />
        </HStack>
      )}
      <AlertDialog
        isCentered
        isOpen={!!confirmingBackId}
        leastDestructiveRef={cancelRef}
        onClose={() => setConfirmingBackId(null)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent boxShadow='0 0 5px black' bg={gradientToCssGradientProp(gradients.nymn)}>
            <AlertDialogHeader py={2} fontSize='lg'>
              <Center>
                <ShadowText
                  style={{
                    fontSize: '1.2rem',
                    fontFamily: 'Encode Sans',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  GO BACK
                </ShadowText>
              </Center>
            </AlertDialogHeader>
            <AlertDialogBody>
              <HStack spacing={0} w='100%'>
                <Button
                  isLoading={confirmingBack}
                  flex={1}
                  fontFamily='Bebas Neue'
                  color='white'
                  onClick={() => handleConfirmBack(false)}
                  px={2}
                  fontSize='xl'
                >
                  GO BACK WITHOUT SAVING
                </Button>
                <Button
                  flex={1}
                  isLoading={confirmingBack}
                  colorScheme='red'
                  fontFamily='Bebas Neue'
                  onClick={() => handleConfirmBack(true)}
                  px={2}
                  fontSize='xl'
                >
                  SAVE WHEEL AND GO BACK
                </Button>
              </HStack>
            </AlertDialogBody>
            <AlertDialogCloseButton />
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  )
}
