import {
  Button, Flex, Portal, Text,
} from '@chakra-ui/react'
import { AppAuthProvider } from '@elan-twitch/shared'
import { useFirebase } from '@elanmc/elan-react'
import { FC, useMemo } from 'react'
import { signOut, useUser } from '../../store/useUser'
import { GoogleSignIn } from './GoogleSignIn'
import { TwitchSignIn } from './TwitchLogin'

export const ProviderSignIn: Record<AppAuthProvider, FC<{ noText?: boolean }>> = {
  google: GoogleSignIn,
  twitch: TwitchSignIn,
}

type SignInProps = {
  providers?: Array<AppAuthProvider>
  layout?: 'row' | 'column'
}

export const SignIn = ({ providers = ['twitch'], layout = 'row' }: SignInProps) => {
  const uniqueProviders = useMemo(() => [...new Set(providers)], [providers])
  const user = useUser((s) => s.user)
  const { auth } = useFirebase()
  return (
    <Flex gap={[1, 1, 2]} align='center' flexFlow={['column', 'column', 'row']}>
      {user ? (
        <Button size='sm' onClick={() => signOut(auth)}>SIGN OUT</Button>
      ) : (
        <>
          <Text color='white' whiteSpace='nowrap' textAlign='center' opacity={0.8} fontWeight={600} fontSize='sm'>
            SIGN IN
          </Text>
          <Flex flexFlow={layout} gap={2} w='100%'>
            {uniqueProviders.map((p) => {
              const Component = ProviderSignIn[p]
              return <Component noText={layout === 'row'} key={p} />
            })}
          </Flex>
        </>
      )}
    </Flex>
  )
}

export const AbsoluteLogin = (props: SignInProps) => (
  <Portal>
    <Flex position='fixed' bottom={4} left={4}>
      <SignIn {...props} />
    </Flex>
  </Portal>
)
