import { SettingsIcon } from '@chakra-ui/icons'
import { Center, HStack, VStack } from '@chakra-ui/react'
import {
  colorValueToHex, Egg, WithId,
} from '@elan-twitch/shared'
import { useFirebase } from '@elanmc/elan-react'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { TooltipIconButton } from 'components/shared/Buttons'
import { LoadingSwitch } from 'components/shared/LoadingSwitch'
import { ShadowText } from 'components/shared/ShadowText'
import { useContext } from 'react'
import { RarityBadge } from '../../../Widgets/RandomDrops/Eggs/RarityBadge'
import { disableEgg, enableEgg } from './utils'

export const EggSelectButton = ({
  egg,
  onOpen,
}: {
  egg: WithId<Egg>
  onOpen: () => void
}) => {
  const { broadcaster } = useContext(BroadcasterContext)
  const { db } = useFirebase()
  const { enabled, name } = egg || {}
  const asHex = colorValueToHex(false, egg.color)
  return (
    <Center
      borderRadius='20px'
      bg={asHex}
      boxShadow={`0 0 8px ${asHex}`}
      w='180px'
      h='110px'
      p={1}
    >
      <Center borderRadius='16px' w='100%' h='100%' p={2} bg='blackAlpha.800'>
        <VStack spacing={1}>
          <HStack w='100%' justify='center'>
            <ShadowText
              style={{
                fontSize: '1.4rem',
                height: '24px',
                flex: 1,
                textAlign: 'center',
              }}
            >
              {name}
            </ShadowText>
          </HStack>
          <HStack w='100%'>
            <LoadingSwitch
              label={enabled ? 'Enabled' : 'Disabled'}
              value={!!enabled}
              onChange={
                enabled
                  ? () => disableEgg(db, broadcaster._id, egg._id)
                  : () => enableEgg(db, broadcaster._id, egg._id)
              }
            />

            <HStack justify='flex-end'>
              <TooltipIconButton
                onClick={onOpen}
                size='xs'
                label='CONFIG'
                icon={<SettingsIcon />}
              />
            </HStack>
          </HStack>
            <RarityBadge rollThreshold={egg.rollThreshold}/>
        </VStack>
      </Center>
    </Center>
  )
}
