import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { GameJamSubmission } from '@elan-twitch/shared'
import {
  DefaultModal,
  FieldMap,
  FileField,
  FileView,
  Form,
  getDateTimeString,
  ImageViewOptions,
  ItemPreviewProps,
  TextField,
  useBorderColor,
  useFirebase,
  WithId,
} from '@elanmc/elan-react'
import { AnyObject } from 'final-form'
import { doc, updateDoc } from 'firebase/firestore'
import { deleteObject, ref } from 'firebase/storage'
import {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { getBroadcasterGameJamSubmissionsCollectionPath } from '../../../backend/db'
import { BroadcasterContext } from '../../../components/App/Broadcasters/context'
import { WhiteTwitchLogo } from '../../../components/Auth/TwitchLogin'
import { GameViewContext, GameViewContextValue } from './context'
import { GameActivity } from './SubmissionActivity'

const SubmissionTitle = ({ submission, isMine }: { submission: WithId<GameJamSubmission>; isMine?: boolean }) => {
  const { name, url } = submission

  return (
    <a href={url} target='_blank' style={{ display: 'flex', flex: isMine ? 'unset' : 1 }} rel='noreferrer'>
      <Button justifyContent='flex-start' variant='link' flex={1} minW='0' fontWeight={600} opacity={0.9} fontSize='xl'>
        {name.trim() || 'No title'}
      </Button>
    </a>
  )
}

const SubmissionAuthor = ({ submission }: { submission: WithId<GameJamSubmission> }) => {
  const { username } = submission
  return (
    <Flex align='center' pb='2px' gap={2}>
      <Text position='relative' top='2px' fontWeight={700} opacity={0.5} fontSize='xs' lineHeight={1}>
        BY
      </Text>
      <Text opacity={0.8} fontWeight={600}>
        {username}
      </Text>
    </Flex>
  )
}

const SubmissionPlaytime = ({ submission }: { submission: WithId<GameJamSubmission> }) => {
  const { playtime, totalPlaytime } = submission
  return (
    <Flex flexFlow='column' align='flex-end' minW='0' flex={1}>
      <Text fontSize='sm'>Playtime: {playtime || 'N/A'}</Text>
      {totalPlaytime ? <Text fontSize='sm'>Total playtime: {totalPlaytime}</Text> : null}
    </Flex>
  )
}

const SubmissionDescription = ({ submission }: { submission: WithId<GameJamSubmission> }) => {
  const { description } = submission
  return (
    <Tooltip p={2} borderRadius={6} textShadow='1px 1px 3px #000000aa' label={description} placement='top' hasArrow>
      <Text overflow='hidden' noOfLines={3} opacity={0.8}>
        {description}
      </Text>
    </Tooltip>
  )
}

const SubmissionDates = ({ submission }: { submission: WithId<GameJamSubmission> }) => {
  const { createdAt } = submission
  return (
    <Flex w='100%' justify='space-between'>
      {createdAt ? (
        <Text textAlign='center' fontSize='sm' opacity={0.7}>
          Submitted on {getDateTimeString(createdAt)}
        </Text>
      ) : null}
      {/* {updatedAt ? (
        <Text textAlign='center' fontSize='sm' opacity={0.7}>
          Last updated on {getDateTimeString(updatedAt)}
        </Text>
      ) : null} */}
    </Flex>
  )
}

const SubmissionVodLink = ({ submission }: { submission: WithId<GameJamSubmission> }) => {
  const { vodLink } = submission
  if (!vodLink) return null
  return (
    <a href={vodLink} target='_blank' rel='noreferrer'>
      <Button bg='#6441A5' _dark={{ bg: '#6441A5' }} _hover={{ bg: '#815cc4', _dark: { bg: '#815cc4' } }} minW='0' flex={1} pl={2}>
        <WhiteTwitchLogo />
        <Text>WATCH</Text>
      </Button>
    </a>
  )
}

const AdminSubmissionVodLink = ({ submission }: { submission: WithId<GameJamSubmission> }) => {
  const { vodLink } = submission
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Flex gap={2} align='center'>
      {vodLink ? (
        <Tooltip label='Edit VOD link' hasArrow>
          <IconButton
            borderRadius='full'
            size='sm'
            onClick={() => setIsOpen(true)}
            aria-label='Edit Submission'
            icon={<EditIcon />}
          />
        </Tooltip>
      ) : (
        <Button gap={1} borderRadius='full' size='sm' onClick={() => setIsOpen(true)}>
          <AddIcon filter={'drop-shadow(1px 1px 3px #000000aa)'} />
          <Text>ADD VOD LINK</Text>
        </Button>
      )}
      <SubmissionVodLink submission={submission} />
      {isOpen ? <AdminUpdateModal onClose={() => setIsOpen(false)} submission={submission} /> : null}
    </Flex>
  )
}

const submissionImageOptions: ImageViewOptions = {
  width: 80,
}
const SubmissionImage = ({ submission }: { submission: WithId<GameJamSubmission> }) => {
  const { thumbnail } = submission
  return <FileView value={thumbnail} imageOptions={submissionImageOptions} />
}

const thumbnailField: FileField = {
  placeholder: 'Thumbnail',
  imageOptions: submissionImageOptions,
  accept: ['image'],
  _type: 'file',
  optional: true,
}

const vodLinkField: TextField = {
  placeholder: 'VOD Link',
  _type: 'text',
  optional: true,
}

const adminUpdateField: FieldMap = {
  name: 'Update Submission',
  children: {
    thumbnail: thumbnailField,
    vodLink: vodLinkField,
  },
}

const AdminUpdateModal = ({ onClose, submission }: { onClose: () => void; submission: WithId<GameJamSubmission> }) => {
  const { db } = useFirebase()
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)
  const { _id, thumbnail, vodLink } = submission

  const storagePath = useMemo(
    () => `${getBroadcasterGameJamSubmissionsCollectionPath(broadcasterId)}/${_id}`,
    [broadcasterId, _id],
  )

  const handleSubmit = useCallback(
    async (data: AnyObject) => {
      const collectionPath = getBroadcasterGameJamSubmissionsCollectionPath(broadcasterId)
      const docRef = doc(db, collectionPath, _id)
      const updated: Partial<GameJamSubmission> = {}
      if (data.thumbnail) updated.thumbnail = data.thumbnail
      if (data.vodLink) updated.vodLink = data.vodLink
      return updateDoc(docRef, data)
        .then(() => ({ success: 'Submission updated' }))
        .catch((err) => ({ error: err.message || 'Failed to update submission' }))
    },
    [_id, db, broadcasterId],
  )

  const value = useMemo(() => ({ thumbnail, vodLink }), [thumbnail, vodLink])

  return (
    <DefaultModal isOpen onClose={onClose}>
      <Box pt={3}>
        <Form storagePath={storagePath} field={adminUpdateField} value={value} onSubmit={handleSubmit} />
      </Box>
    </DefaultModal>
  )
}

const ImagePopoverContent = ({ onOpen, submission }: { onOpen: () => void; submission: WithId<GameJamSubmission> }) => {
  const { db, storage } = useFirebase()
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)
  const toast = useToast()
  const { thumbnail } = submission
  const [loadingDelete, setLoadingDelete] = useState(false)
  const handleDelete = useCallback(async () => {
    setLoadingDelete(true)
    try {
      const collectionPath = getBroadcasterGameJamSubmissionsCollectionPath(broadcasterId)
      const docRef = doc(db, collectionPath, submission._id)
      const currStoragePath = thumbnail?.storagePath
      if (currStoragePath) {
        const storageRef = ref(storage, currStoragePath)
        await deleteObject(storageRef).catch((err) => {
          console.error(err)
        })
      }
      await updateDoc(docRef, { thumbnail: null })
    } catch (err: any) {
      toast({
        title: 'Failed to delete image',
        description: err.message,
        status: 'error',
      })
    }
    setLoadingDelete(false)
  }, [broadcasterId, db, submission, toast, storage, thumbnail])
  return (
    <VStack p={2} w='100%'>
      <Button gap={1} size='sm' onClick={onOpen} w='100%'>
        <EditIcon filter='drop-shadow(1px 1px 3px #00000077)' />
        <Text>{thumbnail ? 'Change' : 'Upload'} Image</Text>
      </Button>
      {thumbnail ? (
        <Button
          gap={1}
          bg='red.500'
          color='white'
          size='sm'
          isLoading={loadingDelete}
          onClick={handleDelete}
          w='100%'
          colorScheme='red'
        >
          <DeleteIcon filter='drop-shadow(1px 1px 3px #00000077)' />
          <Text textShadow='1px 1px 3px #00000077'>Delete Image</Text>
        </Button>
      ) : null}
    </VStack>
  )
}

const AdminSubmissionImage = ({ submission }: { submission: WithId<GameJamSubmission> }) => {
  const [isOpen, setIsOpen] = useState(false)
  const borderColor = useBorderColor()
  return (
    <Box>
      <Flex
        borderRadius={5}
        transition='border 200ms'
        minH='20px'
        width='80px'
        border='1px dashed transparent'
        _hover={{ border: `1px dashed ${borderColor}` }}
        cursor='pointer'
      >
        <Popover trigger='hover' strategy='fixed' placement='right'>
          <PopoverTrigger>
            <Box>
              <Box pointerEvents='none'>
                <SubmissionImage submission={submission} />
              </Box>
            </Box>
          </PopoverTrigger>
          <PopoverContent w='250px'>
            <ImagePopoverContent onOpen={() => setIsOpen(true)} submission={submission} />
          </PopoverContent>
        </Popover>
        {isOpen ? <AdminUpdateModal onClose={() => setIsOpen(false)} submission={submission} /> : null}
      </Flex>
    </Box>
  )
}

export const AdminSubmissionPreview = (props: {
  submission: WithId<GameJamSubmission>
  bg?: string
  refetch?: () => Promise<void>
  isMine?: boolean
}) => <SubmissionPreview {...props} admin />

export const SubmissionPreview = ({
  submission,
  bg,
  refetch,
  isMine,
  admin,
}: {
  submission: WithId<GameJamSubmission>
  bg?: string
  refetch?: () => Promise<void>
  isMine?: boolean
  admin?: boolean
}) => {
  const contextData = useMemo<GameViewContextValue>(
    () => ({ gameId: submission._id, refetch, game: submission }),
    [refetch, submission],
  )
  return (
    <GameViewContext.Provider value={contextData}>
      <Flex h='100%' align='center' py={isMine ? 0 : 1} px={2} w='100%'>
        <Flex borderRadius={6} align='center' flexFlow='column' h='100%' bg={bg || 'background.200'} w='100%'>
          <Flex w='100%' px={3} py={1} borderBottom='1px solid #00000044' align='center'>
            <Stack
              direction={['column', 'column', 'row']}
              py={1}
              justify='space-between'
              divider={<StackDivider />}
              flex={1}
              minW='0'
            >
              <SubmissionTitle isMine={isMine} submission={submission} />
              <SubmissionAuthor submission={submission} />
              <SubmissionPlaytime submission={submission} />
            </Stack>
          </Flex>
          <Flex pb={2} flex={1} direction='column' w='100%' minW='0' px={2}>
            <Flex align='center' px={2} gap={4} flex={1} minH='0' w='100%'>
              <Box w='80px'>
                {admin ? <AdminSubmissionImage submission={submission} /> : <SubmissionImage submission={submission} />}
              </Box>
              <Box py={2} h='100%' flex={1} minW='0'>
                <SubmissionDescription submission={submission} />
              </Box>
              {admin ? (
                <AdminSubmissionVodLink submission={submission} />
              ) : (
                <SubmissionVodLink submission={submission} />
              )}
              <GameActivity />
            </Flex>
            <SubmissionDates submission={submission} />
          </Flex>
        </Flex>
      </Flex>
    </GameViewContext.Provider>
  )
}

export const ItemPreview = ({ item, refetch }: ItemPreviewProps<GameJamSubmission>) => (
  <SubmissionPreview key={item._id} submission={item} refetch={refetch} />
)

export const AdminItemPreview = ({ item, refetch }: ItemPreviewProps<GameJamSubmission>) => (
  <AdminSubmissionPreview key={item._id} submission={item} refetch={refetch} />
)
