import { PermissionsData } from '@elan-twitch/shared'
import { useFirebase } from '@elanmc/elan-react'
import {
  Auth, onAuthStateChanged, signOut as fbSignOut, User,
} from 'firebase/auth'
import { onSnapshot } from 'firebase/firestore'
import { useEffect } from 'react'
import { create } from 'zustand'
import { getPermissionsDoc } from '../backend/db'

export type UserData = {
  user: User | null
  data: PermissionsData | null
  isAdmin: boolean
  loading: boolean
  channelAdminOf?: Array<string>
}

export const useUser = create<UserData>(() => ({
  user: null,
  data: null,
  loading: false,
  isAdmin: false,
}))

export const useAuthUpdate = () => {
  const { auth } = useFirebase()
  const user = useUser((s) => s.user)
  useEffect(
    () => onAuthStateChanged(auth, (newUser) => {
      useUser.setState({ user: newUser })
    }),
    [auth],
  )

  const { db } = useFirebase()
  useEffect(() => {
    if (!user) return () => {}
    useUser.setState({ loading: true })
    return onSnapshot(getPermissionsDoc(db, user.uid), {
      next: (res) => {
        const data = res.data()
        useUser.setState({
          data,
          channelAdminOf: data?.channelAdminOf,
          isAdmin: !!data?.isAdmin,
          loading: false,
        })
      },
      error: (e) => {
        console.error(e)
      },
    })
  }, [user, db])
}

export const signOut = (auth: Auth) => fbSignOut(auth).then(() => useUser.setState({
  user: null,
  isAdmin: false,
  channelAdminOf: undefined,
}))
