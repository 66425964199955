import {
  combineValidators,
  validateInteger,
  validateNonNegativeNumber,
  validatePositiveNumber,
} from '@elan-twitch/shared'
import {
  BooleanField, ColorField, FieldMap, ListField, NumberField, TextField,
} from '@elanmc/elan-react'

const enabledField: BooleanField = {
  _type: 'boolean',
  type: 'switch',
  placeholder: 'Enabled',
}

export const name: TextField = {
  _type: 'text',
  type: 'text',
  placeholder: 'Name',
}

export const eggName: TextField = {
  ...name,
  placeholder: 'Egg Name',
}
//      0 - 1
// if (Math.random() > X)
const betweenZeroAndOneHundredValidator = (value: number) => {
  if (Number.isNaN(value)) {
    return 'Must be a number'
  }
  if (value < 0 || value > 100) {
    return 'Must be between 0 and 100'
  }
  return undefined
}
export const rollThreshold: NumberField = {
  _type: 'number',
  placeholder: 'Required roll (out of 100)',
  precision: 2,
  min: 0,
  label: 'Example: An egg with a roll threshold of 80 will drop 20% of the time',
  validate: combineValidators<number>(betweenZeroAndOneHundredValidator),
}

export const price: NumberField = {
  _type: 'number',
  placeholder: 'Price',
  validate: combineValidators<number>(validatePositiveNumber, validateInteger),
}

export const priceVariance: NumberField = {
  _type: 'number',
  optional: true,
  placeholder: 'Price Variance (optional)',
  label:
    'Amount of points the price can vary by (for example, 10 will make the price +/- 10 points)',
  validate: combineValidators<number>(validateInteger, validateNonNegativeNumber),
}

export const mass: NumberField = {
  _type: 'number',
  placeholder: 'Weight / mass',
  label: 'physics',
  validate: combineValidators<number>(validatePositiveNumber, validateInteger),
}

export const color: ColorField = {
  _type: 'color',
  defaultValue: { r: 80, g: 120, b: 200 },
  placeholder: 'Egg Color',
}

export const emissiveIntensity: NumberField = {
  _type: 'number',
  optional: true,
  placeholder: 'Emissive Intensity (optional)',
  label: 'Glow amount',
  validate: combineValidators<number>(validatePositiveNumber),
}

export const eggField: FieldMap = {
  name: 'Egg',
  children: {
    // name: string // eg 'common', 'uncommon', 'rare', 'epic', 'legendary'
    name,
    // rollThreshold: number // roll required to  get this tier (out of 100)
    rollThreshold,
    // price: number
    price,
    // priceVariance?: number // can be used to randomize price +/- this number
    priceVariance,
    // mass: number
    mass,
    // color: string
    color,
    // emissiveIntensity?: number // optional glow amount
    emissiveIntensity,
  },
}

const eggsField: ListField = {
  _type: 'list',
  placeholder: 'Eggs',
  itemField: eggField,
}

const eggConfigField: FieldMap = {
  name: 'Egg Config',
  children: {
    enabled: { ...enabledField, label: 'Egg Drops Enabled' },
    tiers: eggsField,
  },
}

const droppedItemsField: FieldMap = {
  name: 'Dropped Items',
  children: {
    egg: eggConfigField,
  },
}

export const randomDropsConfigField: FieldMap = {
  name: 'Random Drops Config',
  children: {
    enabled: { ...enabledField, label: 'Random Drops Enabled' },
    droppedItems: droppedItemsField,
  },
}
