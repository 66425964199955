import { Button, Text } from '@chakra-ui/react'
import {
  BroadcasterScopes, getBroadcasterScopesPath, gradients, gradientToCssGradientProp,
} from '@elan-twitch/shared'
import { getScopeStatus } from '@elan-twitch/shared/utils/config'
import { useDocument } from '@elanmc/elan-react'
import { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { TwitchLogo } from '../../Auth/TwitchLogin'
import { BroadcasterContext } from '../Broadcasters/context'
import { useConfigSnippet } from './hooks'

// displays whether all permissions required are granted and if not, provides a button to reconnect to the server
export const Reconnect = () => {
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)
  const { basePath } = useContext(BroadcasterContext)
  const path = useMemo(() => getBroadcasterScopesPath(broadcasterId), [broadcasterId])
  const { config } = useConfigSnippet()
  const { data } = useDocument<BroadcasterScopes>(path)
  const { missingScopes } = useMemo(() => getScopeStatus(config, data), [data, config])
  // const bg = missingScopes.length ? 'red' : 'green'
  const bg = useMemo(
    () => (missingScopes.length ? gradientToCssGradientProp(gradients.red) : gradientToCssGradientProp(gradients.blue)),
    [missingScopes],
  )
  return (
    <Link style={{ width: '100%' }} to={`${basePath}/config/reconnect`}>
      <Button
        w='100%'
        bg={bg}
        _dark={{ bg }}
        size='md'
        borderRadius='full'
      >
        <TwitchLogo filter={'grayscale(100%) brightness(290%) drop-shadow(1px 1px 4px #00000055)'} />
        <Text>{missingScopes.length ? 'Reconnect' : 'Connected'}</Text>
      </Button>
    </Link>
  )
}
