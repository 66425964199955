export const BROADCASTER = 'broadcaster'
export const CONFIG = 'config'
export const SNIPPET = 'snippet'
export const PERMISSIONS = 'permissions'
export const POINTS_BANK = 'pointsBankAccount'
export const MOVIE_LIST = 'movieList'
export const CHAT = 'chat'
export const MOVIE_DETAILS = 'movieDetails'
export const UPVOTES = 'upvotes'
export const DOWNVOTES = 'downvotes'
export const VOTES = 'votes'
export const MOVIE_LIST_VOTES = 'movieListVotes'
export const RANDOM_DROPS = 'randomDrops'
export const WHEEL_AUCTION = 'wheelAuction'
export const POLL = 'poll'
export const PREDICTION = 'prediction'
export const AUCTION = 'auction'
export const USERS = 'users'
export const AUTH = 'auth'
export const RATE_LIMIT = 'rateLimit'
export const BROADCASTER_SCOPES = 'broadcaster-scopes'
export const USER_SCOPES = 'user-scopes'
export const APP_ACCESS = 'app-tokens'
export const TWITCH_EVENT_SUBSCRIPTIONS = 'twitch-event-subscriptions'
export const SUBSCRIPTION_SECRETS = 'subscription-secrets'
export const AUTH_URLS = 'authUrls'
export const CHATBOT_TOKENS = 'chatbot-tokens'
export const GAME_JAM = 'game-jam'
