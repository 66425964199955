import {
  Box, DarkMode, Flex, FlexProps,
} from '@chakra-ui/react'
import { useBorderColor, useScreen } from '@elanmc/elan-react'
import { useMemo } from 'react'
import { AcceptCookies } from '../../components/shared/AcceptCookies'
import { MobileDarkModeToggle } from '../../components/shared/ColorMode/DarkModeToggle'
import { nymnBg } from './constants'
import { Links } from './Home/Links'

const DesktopHeader = () => {
  const borderColor = useBorderColor()
  return (
    <DarkMode>
      <Flex bg='blackAlpha.300' borderBottom={`1px solid ${borderColor}`} w='100%' h='64px'>
        <Links />
      </Flex>
    </DarkMode>
  )
}

export const NymnPage = ({ children, ...props }: FlexProps) => {
  const width = useScreen((s) => s.width)

  const mobileLayout = useMemo(() => width < 769, [width])
  // fill (contain) the video player in the main container - 16:9 aspect ratio

  return (
    <AcceptCookies>
      <Flex
        // @ts-ignore
        gap={[2, 2, 0]}
        pointerEvents='auto'
        flexFlow='column'
        w='100%'
        display={['block', 'block', 'flex']}
        h='100%'
        overflowX='hidden'
        bg={nymnBg}
        {...props}
      >
        {mobileLayout ? null : <DesktopHeader />}
        {/* Body */}
        <Box overflowY='auto' overflowX='hidden' w='100%' minH='0' flex={1}>
          {children}
          {mobileLayout ? <Links /> : null}
        </Box>
        <MobileDarkModeToggle />
      </Flex>
    </AcceptCookies>
  )
}
