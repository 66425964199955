import { FieldMap, NumberField } from '@elanmc/elan-react'
import { twitchEvents, twitchUserScopes } from '../../constants/twitch'
import { gradientField } from '../../fields/colors'
import { Widget } from '../types'
import { PollWidgetConfig } from './types'

export const fallbackPollConfig: PollWidgetConfig = {
  enabled: false,
  barOpacity: 1,
  bgOpacity: 0.5,
  bgVisible: true,
  darkMode: false,
  gradient: 'blue',
  isPortrait: true,
  scale: 1.6,
  swapColors: false,
}

export const pollConfigField: FieldMap = {
  children: {
    scale: {
      _type: 'number',
      validate: (v) => {
        if (v === undefined || Number.isNaN(v)) return 'Not a number'
        // if (v > 3) return 'too big'
        // if (v < 1) return 'too small'
        return undefined
      },
      precision: 2,
      placeholder: 'Scale',
    } as NumberField,
    darkMode: {
      _type: 'boolean',
      placeholder: 'Dark Mode',
    },
    swapColors: {
      _type: 'boolean',
      placeholder: 'Swap Colors',
      condition: (v) => !v?.darkMode,
    },
    bgVisible: {
      condition: (v) => v?.version === 'classic',
      _type: 'boolean',
      placeholder: 'Background visible',
    },
    isPortrait: {
      _type: 'boolean',
      placeholder: 'Vertical layout',
    },
    bgOpacity: {
      condition: (v) => v?.version === 'classic',
      _type: 'number',
      precision: 2,
      validate: (v) => {
        if (v === undefined || Number.isNaN(v)) return 'Not a number'
        if (v > 1) return 'too big'
        if (v < 0) return 'too small'
        return undefined
      },
      placeholder: 'Background opacity',
    } as NumberField,
    barOpacity: {
      _type: 'number',
      validate: (v) => {
        if (v === undefined || Number.isNaN(v)) return 'Not a number'
        if (v > 1) return 'too big'
        if (v < 0.1) return 'too small'
        return undefined
      },
      precision: 3,
      placeholder: 'Bar opacity',
    } as NumberField,
    gradient: gradientField,
  },
}

export const pollOverlayWidget: Widget = {
  id: 'poll-overlay',
  name: 'Poll Overlay',
  configField: pollConfigField,
  requiredScopes: [twitchUserScopes.CHANNEL_READ_POLLS],
  eventTypes: [
    twitchEvents.CHANNEL_POLL_BEGIN,
    twitchEvents.CHANNEL_POLL_PROGRESS,
    twitchEvents.CHANNEL_POLL_END,
  ],
}
