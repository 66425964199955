import { Box, Center, VStack } from '@chakra-ui/react'
import { getBroadcasterMovieListCollectionPath, MovieListMovie } from '@elan-twitch/shared'
import { DataList, InfiniteScrollList } from '@elanmc/elan-react'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { MovieListHeader } from 'components/Widgets/MovieList/Header'
import { useContext, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NymnPage } from '../../../Views/Nymn/NymnPage'
import { AcceptCookies } from '../../shared/AcceptCookies'
import { MovieViewModal } from './MovieView'
import { MovieIdPreview } from './MovieView/MoviePreview'

const getList = (broadcasterId: string): DataList<MovieListMovie> => ({
  itemName: 'movie',
  collectionPath: getBroadcasterMovieListCollectionPath(broadcasterId),
  defaultFilters: [
    {
      firestoreFilter: ['watched', '==', false],
      label: 'Unwatched movies',
    },
  ],
  defaultSort: {
    sortKey: 'vote_score',
    sortDirection: 'desc',
  },
  filterPresets: [
    {
      firestoreFilter: ['watched', '==', false],
      label: 'Unwatched movies',
    },
    {
      firestoreFilter: ['watched', '==', true],
      label: 'Watched movies',
    },
  ],
  sortPresets: [
    {
      defaultDirection: 'desc',
      sortKey: 'vote_score',
      label: 'Score',
    },
    {
      defaultDirection: 'desc',
      sortKey: 'nominatedOn',
      label: 'Date Nominated',
    },
  ],
  Header: MovieListHeader,
  ItemPreview: ({ item }) => (
    <Center w='100%' py='2px' px={1}>
      <MovieIdPreview id={item._id} />
    </Center>
  ),
  previewHeight: 132,
  noItemsMessage: 'No movies added yet, search for a movie to nominate it',
})

export const MovieList = () => {
  const {
    broadcaster: { _id: broadcasterId },
    basePath,
  } = useContext(BroadcasterContext)
  const navigate = useNavigate()
  const { movieId } = useParams<{ movieId: string }>()
  const list = useMemo(() => getList(broadcasterId), [broadcasterId])
  return (
    <InfiniteScrollList
      list={list}
      basePath={`${basePath}/movies`}
      footerProps={{ bg: 'background.200' }}
      modals={<>{movieId ? <MovieViewModal onClose={() => navigate(`${basePath}/movies`)} id={movieId} /> : null}</>}
    />
  )
}

const MovieListViewBody = () => (
  <NymnPage h='100%' display='flex' overflowY='hidden'>
    <VStack
      pointerEvents='auto'
      // opacity={isLoading ? 0 : 1}
      transition='opacity 400ms'
      h='95%'
      align='center'
      w='100%'
      py={5}
    >
      <Box bg='whiteAlpha.500' _dark={{ bg: 'blackAlpha.500' }} maxW='1000px' w='95%' h='100%'>
        <MovieList />
      </Box>
    </VStack>
  </NymnPage>
)

export const MovieListView = () => (
  <AcceptCookies>
    <MovieListViewBody />
  </AcceptCookies>
)
