import {
  AutomaticPointsAuction,
  Broadcaster, getBroadcasterAuctionDocPath, PointsAuction, WithId,
} from '@elan-twitch/shared'
import {
  DBItem, useDocument, useFirebase, useQuery,
} from '@elanmc/elan-react'
import { getBroadcasterAuctionsCollection } from 'backend/db'
import {
  Firestore,
  orderBy,
  Query,
  query,
} from 'firebase/firestore'
import { useMemo } from 'react'

export const usePointsAuction = (broadcaster: WithId<Broadcaster>) => {
  const path = useMemo(
    () => (broadcaster.pointsBankAccountConfig?.currentPointsAuctionId
      ? getBroadcasterAuctionDocPath(
        broadcaster._id,
        broadcaster.pointsBankAccountConfig?.currentPointsAuctionId,
      )
      : null),
    [broadcaster],
  )
  return useDocument<AutomaticPointsAuction>(path)
}

const getSavedPointsAuctionsQuery = (db: Firestore, broadcaster: WithId<Broadcaster>) => query(
  getBroadcasterAuctionsCollection(db, broadcaster._id),
  orderBy('created_at', 'desc'),
) as Query<DBItem<PointsAuction>>

export const useSavedPointsAuctions = (broadcaster: WithId<Broadcaster>) => {
  const { db } = useFirebase()
  const q = useMemo(() => getSavedPointsAuctionsQuery(db, broadcaster), [db, broadcaster])

  return useQuery<PointsAuction>(q)
}
