import { GameVote, getGameJamVoteDocPath } from '@elan-twitch/shared'
import { useDocument } from '@elanmc/elan-react'
import { useContext, useMemo } from 'react'
import { BroadcasterContext } from '../../../components/App/Broadcasters/context'
import { useUser } from '../../../store/useUser'

export const useMyGameJamVote = (submissionId: string) => {
  const { broadcaster: { _id: broadcasterId } } = useContext(BroadcasterContext)
  const user = useUser((s) => s.user)

  const myVotePath = useMemo(() => (user ? getGameJamVoteDocPath(broadcasterId, submissionId, user.uid) : null), [user, broadcasterId, submissionId])

  return useDocument<GameVote>(myVotePath)
}
