import { ArrowForwardIcon, CalendarIcon } from '@chakra-ui/icons'
import {
  Box, Button, Center, Flex, IconButton, Text,
} from '@chakra-ui/react'
import { FridayReviews, getDateString } from '@elan-twitch/shared'
import {
  PropsWithChildren, useContext, useEffect, useMemo, useRef, useState,
} from 'react'
import { BroadcasterContext } from '../../../components/App/Broadcasters/context'
import { PulsingCircle } from '../../../components/shared/Animation/PulsingCirlce'
import { Loading } from '../../../components/shared/Loading'
import { useFeathers } from '../../../feather/provider'

const FridayReviewPreview = ({ review }: { review: FridayReviews }) => {
  const {
    title, review_submissions: submissions, active, createdAt,
  } = review

  return (
    <Flex color='gray.50' flexFlow='column' gap={2} h='100%' justify='space-between' p={3} w='100%'>
      <Flex gap={1} flexFlow='column'>
        <Flex w='100%' align='center'>
          <Flex py={1} gap={1} align='center'>
            <PulsingCircle active={active} />
            <Text lineHeight={1} fontFamily='Bakbak One' fontWeight={400} color={active ? 'green.400' : 'gray.400'}>
              {active ? 'ACTIVE' : 'INACTIVE'}
            </Text>
          </Flex>
          <Flex opacity={0.7} ml='auto' gap={1} align='center'>
            <CalendarIcon />
            <Text fontWeight={600} fontFamily='Noto Sans' fontSize='sm' lineHeight={1}>
              {getDateString(createdAt, 'short')}
            </Text>
          </Flex>
        </Flex>
        <Text lineHeight={1} opacity={0.8} fontWeight={600} color='gray.50' fontSize='sm' fontFamily='Noto Sans'>
          FRIDAY REVIEW
        </Text>
        <Text lineHeight={1} fontSize='lg' fontFamily='Noto Sans'>
          {title}
        </Text>
      </Flex>
      <Text>
        {submissions.length} submission{submissions.length === 1 ? '' : 's'}
      </Text>
    </Flex>
  )
}

export const FridayReviewsPreview = () => {
  const { client } = useFeathers()
  const {
    broadcaster: { _id: channel },
  } = useContext(BroadcasterContext)

  const [reviews, setReviews] = useState<FridayReviews[] | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const mounted = useRef(false)

  useEffect(() => {
    if (!client || mounted.current) return
    mounted.current = true
    client
      .service('reviews')
      .find({
        query: {
          $sort: {
            createdAt: -1,
          },
        },
      })
      .then((res) => {
        setIsLoading(false)
        const data = res.data as FridayReviews[]
        setReviews(data)
        // setContests(res.data.sort((a, b) => Number(b.active) - Number(a.active)))
      })
      .catch((err) => {
        setIsLoading(false)
        console.error(err)
      })
  }, [channel, client])
  const mostRecentReview = useMemo(() => {
    if (!reviews) return null
    return reviews.filter((c) => !!c.active).sort((a, b) => Number(b.createdAt) - Number(a.createdAt))[0]
  }, [reviews])
  let body: PropsWithChildren['children'] = null
  if (mostRecentReview) {
    body = <FridayReviewPreview review={mostRecentReview} />
  } else if (isLoading) {
    body = <Center w='100%' p={3}>
    <Loading text='Loading reviews...' />
  </Center>
  } else {
    body = (
      <Center w='100%' p={3}>
    <Text>No active reviews</Text>
    </Center>
    )
  }
  return (
    <Flex color='gray.50' flexFlow='column' w='100%' h='100%' bg='blackAlpha.400' borderRadius={6}>
      <Flex borderBottom='1px solid' borderColor='#ffffff55' px={3} py={1} align='center' w='100%'>
        <a href='https://nymn.gg/review' target='_blank' >
          <Button color='gray.50' variant='link' fontFamily='Noto Sans' fontWeight={600} textShadow='1px 1px 3px #00000077'>
            Friday Reviews
          </Button>
        </a>
        <Box ml='auto'>
          {/* <Link to={`${basePath}/reviews`}> */}
          <a href='https://nymn.gg/review' target='_blank'>
            <IconButton
              ml='auto'
              borderRadius='full'
              size='sm'
              color='gray.50'
              icon={<ArrowForwardIcon w={5} h={5} />}
              aria-label='View full movie list'
              variant='ghost'
            />
          </a>
          {/* </Link> */}
        </Box>
      </Flex>
      {body}
    </Flex>
  )
}
