import { useToast } from '@chakra-ui/react'
import { useFirebase } from '@elanmc/elan-react'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { handleBroadcasterAuthRedirect } from '../backend/functions'
import { useTimeout } from './useTimeout'

export const useAuthRedirect = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [_error, setError] = useState<string | null>(null)
  const error = useTimeout(_error)
  const toast = useToast()
  const navigate = useNavigate()
  const { functions } = useFirebase()
  useEffect(() => {
    const { pathname, search } = window.location
    if (pathname === '/auth-code-redirect') {
      const params = search
        .substring(1)
        .split('&')
        .reduce((acc, curr) => {
          const [key, val] = curr.split('=')
          return { ...acc, [key]: val }
        }, {} as Record<string, string>)
      const { code, state, error: err } = params
      if (!code) {
        toast({
          title: 'Error',
          description: err || 'No code found in redirect',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      }
      setIsLoading(true)
      handleBroadcasterAuthRedirect(functions)({ code, state })
        .then(async (res) => {
          setIsLoading(false)
          const { data } = res
          if (data.pathname) {
            toast({
              title: 'Success',
              description: 'You have successfully connected your Twitch account',
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
            navigate(decodeURIComponent(data.pathname))
          } else {
            setError(data.error || 'Error connecting Twitch account')
            toast({
              title: 'Error',
              description: data.error,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          }
        })
        .catch((tokenErr) => {
          setIsLoading(false)
          console.error(tokenErr)
          setError('Error subscribing to events')
          console.error(tokenErr)
        })
    }
  }, [navigate, toast, functions])
  const message = useMemo(() => {
    if (isLoading) return { text: 'Subscribing to events...', color: '#ffffffdd' }
    if (error) return { text: 'Error subscribing to events :(', color: '#ffabab' }
    return null
  }, [isLoading, error])

  return {
    isLoading,
    error,
    message,
  }
}
