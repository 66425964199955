import { BROADCASTER, GameJamSubmission, GAME_JAM } from '@elan-twitch/shared'
import { DataList } from '@elanmc/elan-react'
import { AdminItemPreview, ItemPreview } from './SubmissionPreview'

export const getSubmissionsList = (broadcasterId: string, isAdmin: boolean): DataList<GameJamSubmission> => ({
  collectionPath: `${BROADCASTER}/${broadcasterId}/${GAME_JAM}`,
  defaultFilters: [],
  filterPresets: [],
  itemName: 'Submission',
  limit: 8,
  ItemPreview: isAdmin ? AdminItemPreview : ItemPreview,
  defaultSort: {
    sortDirection: 'asc',
    sortKey: 'createdAt',
  },
  previewHeight: 200,
  mobile: {
    ItemPreview: isAdmin ? AdminItemPreview : ItemPreview,
    previewHeight: 300,
  },
  sortPresets: [
    // {
    //   defaultDirection: 'asc',
    //   label: 'Submission Date',
    //   sortKey: 'createdAt',
    // },
    // {
    //   defaultDirection: 'desc',
    //   label: 'Vote Score',
    //   sortKey: 'voteScore',
    // },
  ],
})
