import { Button, Image, Text } from '@chakra-ui/react'
import { useFirebase } from '@elanmc/elan-react'
import { popupSignIn } from 'backend/auth'
import googleLogo from '../../icons/google_logo.svg'

export const GoogleSignIn = ({ noText }: { noText?: boolean }) => {
  const { auth } = useFirebase()
  return (
  <Button gap={1.5} size='sm' flex={1} onClick={() => popupSignIn(auth)}>
    <Image src={googleLogo} alt='Google' boxSize={4} />
    {noText ? null : <Text color='inherit'>SIGN IN</Text>}
  </Button>
  )
}
