import { Button, Image, Text } from '@chakra-ui/react'
import { AccessLevel } from '@elanmc/elan-react'
import {
  createContext, ReactNode, useContext, useMemo, useState,
} from 'react'
import { useIsChannelAdmin } from '../hooks/useIsChannelAdmin'

type ViewAsData = {
  viewAs: AccessLevel
  toggleViewAs?: () => void
}

export const ViewAsContext = createContext<ViewAsData>({ viewAs: 'user' })

export const ViewAsProvider = ({ children }: { children: ReactNode }) => {
  const [viewAsUser, setViewAsUser] = useState(false)
  const isChannelAdmin = useIsChannelAdmin()

  const viewAs = useMemo(() => {
    if (!isChannelAdmin) return 'user'
    return viewAsUser ? 'user' : 'admin'
  }, [viewAsUser, isChannelAdmin])

  const contextValue = useMemo<ViewAsData>(
    () => ({
      viewAs,
      toggleViewAs: isChannelAdmin ? () => setViewAsUser((prev) => !prev) : undefined,
    }),
    [viewAs, setViewAsUser, isChannelAdmin],
  )

  return <ViewAsContext.Provider value={contextValue}>{children}</ViewAsContext.Provider>
}

const xor = (a: any, b: any) => (a ? !b : b)
export const useViewAs = () => useContext(ViewAsContext)
export const useViewingAs = () => useViewAs().viewAs
export const ViewAsToggle = () => {
  const { toggleViewAs, viewAs } = useViewAs()
  const [hovered, setHovered] = useState(false)
  const [justToggled, setJustToggled] = useState(false)

  const src = useMemo(
    () => (xor(hovered && !justToggled, viewAs === 'admin') ? '/assets/images/mod_badge.png' : '/assets/images/emotes/FeelsDankMan.webp'),
    [hovered, viewAs, justToggled],
  )

  const text = useMemo(() => {
    if (hovered && !justToggled) return viewAs === 'admin' ? 'View as User' : 'View as Admin'
    return viewAs === 'admin' ? 'Viewing as Admin' : 'Viewing as User'
  }, [hovered, viewAs, justToggled])

  if (!toggleViewAs) return null
  return (
    <Button
      w='190px'
      gap={2}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => {
        setHovered(false)
        setJustToggled(false)
      }}
      onClick={() => {
        toggleViewAs()
        setJustToggled(true)
      }}
    >
      <Image src={src} h='20px' />
      <Text lineHeight={1}>{text}</Text>
    </Button>
  )
}
