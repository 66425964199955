import { CloseIcon } from '@chakra-ui/icons'
import {
  Flex, IconButton, Text, Tooltip, useColorModeValue, useToast,
} from '@chakra-ui/react'
import { GameVote, StarRatingItem } from '@elan-twitch/shared'
import { CollapseHorizontal } from '@elanmc/elan-react'
import { useCallback, useMemo, useState } from 'react'
import { FULL_STAR } from './svgPaths'

export type StarRatingProps<T extends StarRatingItem> = {
  item: T
  onVote?: (score: GameVote['score'] | null) => Promise<void>
  maxScore?: number
  admin?: boolean
  myVote?: GameVote | null
  height?: number
}

type StarProps = {
  onClick?: () => void
  index: number
  score: number
  hoveredScore: GameVote['score'] | null
  onHover?: (hovered: boolean) => void
  id: string
}

const STAR_SIZE = 21

const Star = ({
  onClick, index, score, id, hoveredScore, onHover,
}: StarProps) => {
  const usedScore = useMemo(() => (hoveredScore === null ? score : hoveredScore), [hoveredScore, score])
  const starScore = useMemo(() => Math.min(1, Math.max(0, usedScore - index)), [usedScore, index])
  const emptyBg = useColorModeValue('#00000033', '#ffffff55')
  const x = useMemo(() => index * STAR_SIZE, [index])
  const transform = useMemo(() => `translate(${x}, 0)`, [x])
  const clipPath = useMemo(
    () => (
      <clipPath id={`${id}-star-clip-${index}`} clipPathUnits='objectBoundingBox'>
        <rect cx={x} y={0} width={starScore} height={1} />
      </clipPath>
    ),
    [starScore, id, index, x],
  )
  const onMouseEnter = useCallback(() => {
    if (onHover) onHover(true)
  }, [onHover])

  const onMouseLeave = useCallback(() => {
    if (onHover) onHover(false)
  }, [onHover])

  return (
    <>
      {clipPath}
      <path
        onMouseEnter={onHover ? onMouseEnter : undefined}
        onMouseLeave={onHover ? onMouseLeave : undefined}
        onClick={onClick}
        transform={transform}
        pointerEvents='bounding-box'
        fill={emptyBg}
        d={FULL_STAR}
      />
      <path
        transform={transform}
        fill={`url(#${id}-score-gradient)`}
        pointerEvents='none'
        d={FULL_STAR}
        clipPath={`url(#${id}-star-clip-${index})`}
      />
    </>
  )
}

export const StarRating = <T extends StarRatingItem>({
  item,
  onVote,
  maxScore = 5,
  admin,
  myVote,
  height = 24,
}: StarRatingProps<T>) => {
  const stars = useMemo(() => Array.from({ length: maxScore }, (_, i) => i), [maxScore])
  const { voteScore = 0, voteCount = 0 } = item
  const [hoveredScore, setHoveredScore] = useState<GameVote['score'] | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const displayedScore = useMemo(() => (admin ? voteScore : myVote?.score || 0), [voteScore, myVote, admin])
  const handleVote = useCallback(
    async (score: GameVote['score'] | null) => {
      setIsLoading(true)
      try {
        if (onVote) await onVote(score)
      } catch (e: any) {
        toast({
          title: 'Error voting',
          description: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [onVote, toast],
  )

  const onScoreHover = useCallback((index: number, hovered: boolean) => {
    if (hovered) setHoveredScore((index + 1) as GameVote['score'])
    else setHoveredScore(null)
  }, [])

  return (
    <Flex align='center' position='relative' direction='row'>
      <Flex position='relative' direction='column'>
        {
          admin ? (

        <Text
          userSelect='none'
          fontWeight={600}
          opacity={displayedScore ? 0.7 : 0.5}
          lineHeight={1}
          mb={1}
          textAlign='center'
        >
          {voteScore.toFixed(2)}
        </Text>
          ) : null
        }
        <Flex position='relative' align='center'>
          <svg viewBox={`0 0 ${STAR_SIZE * maxScore} ${STAR_SIZE}`} height={`${height}px`}>
            <linearGradient id={`${item._id}-score-gradient`}>
              <stop stopColor='#bfa000' offset='0%' />
              <stop stopColor='#ffc000' offset='100%' />
            </linearGradient>
            {stars.map((i) => (
              <Star
                key={i}
                index={i}
                onClick={onVote ? () => handleVote((i + 1) as GameVote['score']) : undefined}
                score={displayedScore}
                onHover={onVote ? (h) => onScoreHover(i, h) : undefined}
                hoveredScore={hoveredScore}
                id={item._id}
              />
            ))}
          </svg>
        </Flex>
        {admin ? (
          <Text userSelect='none' lineHeight={1} fontSize='sm' opacity={0.7} textAlign='center'>
            {voteCount} vote{voteCount === 1 ? '' : 's'}
          </Text>
        ) : null}
      </Flex>
      <CollapseHorizontal width={30} active={myVote !== null}>
        <Tooltip hasArrow label='Remove vote' placement='top'>
          <IconButton
            icon={<CloseIcon w={2} />}
            w={5}
            h={5}
            minW={0}
            borderRadius='full'
            aria-label='Remove vote'
            onClick={() => handleVote(null)}
            isLoading={isLoading}
          />
        </Tooltip>
      </CollapseHorizontal>
    </Flex>
  )
}
