import { useToast } from '@chakra-ui/react'
import { useFirebase } from '@elanmc/elan-react'
import { useCallback, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getThirdPartyAuthUrl } from '../backend/functions'
import { BroadcasterContext } from '../components/App/Broadcasters/context'

export const useTwitchUserSignIn = () => {
  const toast = useToast()
  const { broadcaster } = useContext(BroadcasterContext)
  const { pathname } = useLocation()
  const { functions } = useFirebase()
  const [isSigningIn, setIsSigningIn] = useState(false)
  const [domain] = useState(window.location.host)
  const [protocol] = useState(window.location.protocol)
  const handleSignIn = useCallback(async () => {
    setIsSigningIn(true)
    try {
      const {
        data: { url },
      } = await getThirdPartyAuthUrl(functions)({
        broadcasterId: broadcaster._id,
        sourceDomain: `${protocol}//${domain}`,
        scopes: ['user:read:email'],
        provider: 'twitch',
        pathname,
      })
      window.location.href = url
    } catch (err: any) {
      console.error(err)
      toast({
        status: 'error',
        title: 'Error signing in',
        description: err.message,
      })
    }
  }, [pathname, broadcaster, toast, domain, protocol, functions])
  return { handleSignIn, isSigningIn }
}
