import { Flex, Text } from '@chakra-ui/react'
import { getBroadcasterMovieListCollectionPath, MovieListMovie, WithId } from '@elan-twitch/shared'
import { useFirebase } from '@elanmc/elan-react'
import { doc, DocumentReference } from 'firebase/firestore'
import { useContext, useMemo } from 'react'
import { useDocument } from '../../../../backend/hooks'
import { BroadcasterContext } from '../../../App/Broadcasters/context'
import { ContentBox } from '../../../shared/ContentBox'
import { FetchedItemView } from '../../../shared/FetchedItemView'
import { MovieViewContext } from './context'
import { MovieWatchedStatus, VoteOnMovie } from './MovieActivity'
import { MovieNominatedStatus } from './MovieNominatedStatus'
import {
  MovieCast, MovieDirector, MoviePosterView, MovieReleaseYear,
} from './MovieSummary'

export const MoviePreview = ({ movie, refetch }: { movie: WithId<MovieListMovie>; refetch?: () => Promise<void> }) => (
  <MovieViewContext.Provider value={{ movieId: movie._id, refetch }}>
    <ContentBox p={2} gap={3} w='100%'>
      <Flex>
        <MoviePosterView height={100} movie={movie} />
      </Flex>
      <Flex flex={1} minW='0' flexFlow='column'>
        <Flex gap={2} align='center' w='100%'>
          <Text fontSize='xl' fontWeight={500} isTruncated>
            {movie.title}
          </Text>
          <MovieReleaseYear release_date={movie.release_date} />
        </Flex>
        <MovieDirector movie={movie} />
        <MovieCast movie={movie} />
        <Flex gap={2} pt={2}>
          <MovieWatchedStatus size='sm' timestamp={movie.marked_watched_on} />
        <MovieNominatedStatus movie={movie} />
        </Flex>
      </Flex>
      {/* <Center h='100%'>
      </Center> */}
      <Flex pr={6} align='center'>
        <VoteOnMovie refetchItemOnSuccess movie={movie} />
      </Flex>
    </ContentBox>
  </MovieViewContext.Provider>
)

export const MovieIdPreview = ({ id }: { id: string }) => {
  const { db } = useFirebase()
  const { broadcaster: { _id: broadcasterId } } = useContext(BroadcasterContext)
  const path = useMemo(() => getBroadcasterMovieListCollectionPath(broadcasterId), [broadcasterId])
  const docRef = useMemo(() => doc(db, path, id) as DocumentReference<MovieListMovie>, [db, path, id])
  const { data, isLoading, error } = useDocument(docRef)

  return (
    <FetchedItemView
      flexProps={{
        width: '100%', align: 'center', justify: 'center',
      }}
      Render={(item) => <MoviePreview movie={item} />}
      itemType='movie'
      data={data}
      isLoading={isLoading}
      error={error?.message || null}
    />
  )
}
