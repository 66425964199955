import { Center, CircularProgress, Text } from '@chakra-ui/react'
import { Broadcaster, customDomains, WithId } from '@elan-twitch/shared'
import { useDocument } from '@elanmc/elan-react'
import { PointsAuctionPreview } from 'components/Widgets/Auction/Preview'
import { EmoteStockGraphView } from 'components/Widgets/Chat/EmoteStockGraphView'
import { EmoteTugOfWarView } from 'components/Widgets/Chat/EmoteTugOfWarView'
import { LiveChatViewWidget } from 'components/Widgets/Chat/LiveChatView'
import { MovieListView } from 'components/Widgets/MovieList'
import { RandomDropsOverlay } from 'components/Widgets/RandomDrops'
import { useMemo, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { GameJamPage } from '../../../Views/Nymn/GameJam/GameJam'
import { NymnHomePage } from '../../../Views/Nymn/Home'
// import { NymnHomePage } from '../../../Views/Nymn/Home'
import { PointsAuctionsView } from '../../Widgets/Auction/PointsAuctionView'
import { PointsBankView } from '../../Widgets/PointsBank'
import { UserInventory } from '../../Widgets/RandomDrops/Inventory/Inventory'
import { WheelView } from '../../Widgets/Wheel'
import { WheelPreview } from '../../Widgets/Wheel/Preview'
import { Config } from '../Config'
import { PollView } from '../Poll/PollView'
import { PredictionView } from '../Prediction/PredictionView'
import { BroadcasterContext, BroadcasterContextData } from './context'

const getDomainRoute = (route: string, domainBroadcasterId: string | undefined) => `${domainBroadcasterId ? '' : '/:broadcasterId'}${route}`

const BroadcasterRoutes = ({
  broadcasterId,
  broadcaster,
  domainBroadcasterId,
}: {
  broadcasterId: string
  broadcaster: WithId<Broadcaster>
  domainBroadcasterId: string | undefined
}) => {
  const data = useMemo(
    () => ({
      broadcasterId,
      broadcaster,
      basePath: domainBroadcasterId ? '' : `/${broadcasterId}`,
      domainBroadcaster: domainBroadcasterId,
      // pollComponents[theme.poll.version],
    }),
    [broadcasterId, broadcaster, domainBroadcasterId],
  )
  const { broadcasterId: usedBroadcasterId } = data

  const chatData = useMemo(() => ({ broadcasterId }), [broadcasterId])
  const homePageRoute = useMemo(() => (usedBroadcasterId === 'nymn' ? '/*' : null), [usedBroadcasterId])
  const gameJamRoute = useMemo(() => getDomainRoute('/gamejam', domainBroadcasterId), [domainBroadcasterId])
  const predictionsRoute = useMemo(() => getDomainRoute('/predictions', domainBroadcasterId), [domainBroadcasterId])
  const pollsRoute = useMemo(() => getDomainRoute('/polls', domainBroadcasterId), [domainBroadcasterId])
  const configRoute = useMemo(() => getDomainRoute('/config/*', domainBroadcasterId), [domainBroadcasterId])
  const chatRoute = useMemo(() => getDomainRoute('/chat', domainBroadcasterId), [domainBroadcasterId])
  const tugOfWarRoute = useMemo(() => getDomainRoute('/tugofwar', domainBroadcasterId), [domainBroadcasterId])
  const wheelRoute = useMemo(() => getDomainRoute('/wheel', domainBroadcasterId), [domainBroadcasterId])
  const wheelPreviewRoute = useMemo(() => getDomainRoute('/wheel/preview', domainBroadcasterId), [domainBroadcasterId])
  const bankRoute = useMemo(() => getDomainRoute('/bank', domainBroadcasterId), [domainBroadcasterId])
  const auctionRoute = useMemo(() => getDomainRoute('/auction', domainBroadcasterId), [domainBroadcasterId])
  const auctionPreviewRoute = useMemo(
    () => getDomainRoute('/auction/preview', domainBroadcasterId),
    [domainBroadcasterId],
  )
  const dropsRoute = useMemo(() => getDomainRoute('/drops', domainBroadcasterId), [domainBroadcasterId])
  const inventoryRoute = useMemo(() => getDomainRoute('/bank/:userId', domainBroadcasterId), [domainBroadcasterId])
  const stockGraphRoute = useMemo(() => getDomainRoute('/stockgraph', domainBroadcasterId), [domainBroadcasterId])
  const movieListRoute = useMemo(() => getDomainRoute('/movies', domainBroadcasterId), [domainBroadcasterId])
  return (
    <BroadcasterContext.Provider value={data as BroadcasterContextData}>
      <Routes >
        {
          homePageRoute ? <Route index path={homePageRoute} element={<NymnHomePage />} /> : null
        }
        {
          gameJamRoute ? <Route path={gameJamRoute} element={<GameJamPage/>} /> : null
        }
        <Route path={predictionsRoute} element={<PredictionView />} />
        <Route path={pollsRoute} element={<PollView />} />
        <Route path={configRoute} element={<Config />} />
        <Route path={chatRoute} element={<LiveChatViewWidget data={chatData} />} />
        <Route path={tugOfWarRoute} element={<EmoteTugOfWarView data={chatData} />} />
        <Route path={wheelRoute} element={<WheelView />} />
        <Route path={wheelPreviewRoute} element={<WheelPreview />} />
        <Route path={bankRoute} element={<PointsBankView />} />
        <Route path={auctionRoute} element={<PointsAuctionsView />} />
        <Route path={auctionPreviewRoute} element={<PointsAuctionPreview />} />
        <Route path={bankRoute} element={<PointsBankView />} />
        <Route path={dropsRoute} element={<RandomDropsOverlay />} />
        <Route path={inventoryRoute} element={<UserInventory />} />
        <Route path={stockGraphRoute} element={<EmoteStockGraphView data={chatData} />} />
        <Route path={movieListRoute} element={<MovieListView />} />
        <Route path={`${movieListRoute}/:movieId`} element={<MovieListView />} />
      </Routes>
    </BroadcasterContext.Provider>
  )
}

export const BroadcasterPanel = () => {
  const [domainBroadcaster] = useState(customDomains[window.location.hostname])
  const { pathname } = useLocation()
  const broadcasterId = useMemo(() => {
    const parsed = domainBroadcaster || pathname.split('/').filter((s) => !!s)[0]
    return parsed
  }, [pathname, domainBroadcaster])

  const docId = useMemo(() => (broadcasterId ? `broadcaster/${broadcasterId}` : null), [broadcasterId])
  const { data: broadcaster, isLoading } = useDocument<Broadcaster>(docId)

  if (!broadcaster && isLoading) {
    return (
      <Center w='100%' h='100%' gap={2} p={4}>
        <CircularProgress size={5} isIndeterminate color='gray.600' />
        <Text>Loading...</Text>
      </Center>
    )
  }
  if (!broadcasterId) {
    return (
      <Text bg='red.600' p={3} color='white'>
        Error: missing broadcaster ID
      </Text>
    )
  }
  if (!broadcaster) {
    return pathname.startsWith('/auth-code-redirect') ? null : (
      <Text bg='red.600' p={3} color='white'>
        Error - missing broadcaster: {broadcasterId}
      </Text>
    )
  }
  return (
    <BroadcasterRoutes
      broadcasterId={broadcasterId}
      broadcaster={broadcaster}
      domainBroadcasterId={domainBroadcaster}
    />
  )
}
