import { GameJamSubmission, WithId } from '@elan-twitch/shared'
import { createContext } from 'react'

export type GameViewContextValue = {
  gameId: string
  game: WithId<GameJamSubmission>
  refetch?: () => void
  onClose?: () => void
}
export const GameViewContext = createContext<GameViewContextValue>({
  gameId: '',
  refetch: () => {},
  game: {
    _id: '',
    createdAt: 0,
    createdBy: '',
    description: '',
    name: '',
    voteScore: 0,
    voteCount: 0,
    voteTotal: 0,
    updatedAt: 0,
    playtime: '',
    updatedBy: '',
    url: '',
    username: '',
  },
})
