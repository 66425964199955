import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Box, Button, DarkMode, Flex, IconButton,
} from '@chakra-ui/react'
import { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { BroadcasterContext } from '../../../components/App/Broadcasters/context'
import { GameJamContent } from './GameJamInfo'

export const GameJamPreview = () => {
  const { basePath } = useContext(BroadcasterContext)
  const path = useMemo(() => `${basePath}/gamejam`, [basePath])
  return (
    <Flex color='gray.50' flexFlow='column' w='100%' h='100%' overflow='hidden' bg='blackAlpha.400' borderRadius={6}>
      <Flex borderBottom='1px solid' borderColor='#ffffff55' px={3} py={1} align='center' w='100%'>
        <Link to={path}>
          <Button
            color='gray.50'
            variant='link'
            fontFamily='Noto Sans'
            fontWeight={600}
            textShadow='1px 1px 3px #00000077'
          >
            NymN's Game Jam: Horror Edition
          </Button>
        </Link>
        <Box ml='auto'>
          {/* <Link to={`${basePath}/reviews`}> */}
          <Link to={path}>
            <IconButton
              ml='auto'
              borderRadius='full'
              size='sm'
              color='gray.50'
              icon={<ArrowForwardIcon w={5} h={5} />}
              aria-label='View full movie list'
              variant='ghost'
            />
          </Link>
        </Box>
      </Flex>
      <Flex flex={1} minH='0' h='300px' overflowY='auto' gap={2} p={2} flexFlow='column' w='100%'>
        <DarkMode>
          <GameJamContent />
        </DarkMode>
      </Flex>
    </Flex>
  )
}
