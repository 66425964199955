import {
  Box, Flex, HStack, Text, VStack,
} from '@chakra-ui/react'
import {
  getBroadcasterPointsBankCollectionPath,
  gradients,
  gradientToCssGradientProp,
  PointsBankAccount,
  toPlural,
  truncateNumber,
  WithId,
} from '@elan-twitch/shared'
import { DataList, InfiniteScrollList } from '@elanmc/elan-react'
import { Trophy } from 'icons/Trophy'
import { useContext, useMemo } from 'react'
import { NymnPage } from '../../../Views/Nymn/NymnPage'
import { BroadcasterContext } from '../../App/Broadcasters/context'
import { MobileDarkModeToggle } from '../../shared/ColorMode/DarkModeToggle'
import { ShadowText } from '../../shared/ShadowText'

const PointsBankAccountView = ({ account, index }: { account: WithId<PointsBankAccount>; index: number }) => {
  const { broadcaster } = useContext(BroadcasterContext)
  const { pointsBankAccountConfig } = broadcaster
  const { pointIconPath } = pointsBankAccountConfig || {}
  const { user_name, points, inventory } = account || {}
  const numItems = useMemo(() => {
    const { items } = inventory || {}
    return Object.keys(items || {}).length || 0
  }, [inventory])
  return (
    <Box minW='0' w='100%' p={2}>
      <Flex
        minW='0'
        // bg='background.100'
        bg={gradientToCssGradientProp(gradients.nymn)}
        alignItems='center'
        cursor='pointer'
        boxShadow='1px 1px 3px #00000033'
        _hover={{
          boxShadow: '1px 1px 5px #00000077',
        }}
        transition='box-shadow 400ms'
        borderRadius='md'
        py={2}
        w='100%'
        px={3}
      >
        <Text color='white' textShadow='1px 1px 3px #000000cc'>
          #{index + 1}
        </Text>
        <Text color='white' minW='0' isTruncated pl={2} flex={1} textShadow='1px 1px 3px #000000cc'>
          {user_name}
        </Text>
        <Text
          fontFamily='Bebas Neue'
          textShadow='1px 1px 3px black'
          fontSize='xl'
          pos='relative'
          top='1px'
          lineHeight={1}
          color='gold'
        >
          {truncateNumber(points)}
        </Text>
        {pointIconPath ? (
          <Box
            w='1.5rem'
            ml={1}
            h='1.5rem'
            bgImage={`url(${pointIconPath})`}
            bgSize='cover'
            bgPosition='center'
            borderRadius='full'
          />
        ) : null}
        {numItems ? (
          <HStack spacing={0} ml={3}>
            <Trophy style={{ width: '24px' }} color='gold' />
            <Text
              fontFamily='Bebas Neue'
              textShadow='1px 1px 3px black'
              fontSize='xl'
              top='1px'
              lineHeight={1}
              color='gold'
            >
              x{numItems}
            </Text>
          </HStack>
        ) : null}
      </Flex>
    </Box>
  )
}

const getList = (broadcasterId: string): DataList<PointsBankAccount> => ({
  collectionPath: getBroadcasterPointsBankCollectionPath(broadcasterId),
  itemName: 'account',
  defaultFilters: [],
  filterPresets: [],
  sortPresets: [
    {
      defaultDirection: 'desc',
      sortKey: 'points',
      label: 'Score',
    },
  ],
  getItemPath: (acc) => acc.user_name.toLowerCase(),
  ItemPreview: ({ item, index }) => <PointsBankAccountView index={index} account={item} />,
  previewHeight: 50,
  noItemsMessage: 'No accounts yet',
  defaultSort: {
    sortKey: 'points',
    sortDirection: 'desc',
  },
})

export const PointsBankView = () => {
  const { broadcaster } = useContext(BroadcasterContext)
  const { _id: broadcasterId } = broadcaster || {}
  const { pointsBankAccountConfig } = broadcaster

  const list = useMemo(() => getList(broadcasterId), [broadcasterId])

  if (!broadcasterId) {
    return <Text>Error: missing broadcaster ID</Text>
  }
  if (!pointsBankAccountConfig) {
    return <Text>No points set up for {broadcasterId}</Text>
  }

  const pointName = pointsBankAccountConfig?.pointName ? toPlural(pointsBankAccountConfig?.pointName) : 'points'
  return (
    <NymnPage display='flex' overflowY='hidden' bg={gradientToCssGradientProp(gradients.nymn)} w='100%' height='100%'>
      <VStack
        pointerEvents='auto'
        // opacity={isLoading ? 0 : 1}
        transition='opacity 400ms'
        h='95%'
        w='100%'
        align='center'
        py={5}
      >
        <ShadowText style={{ fontSize: '2rem' }}>{pointName.toUpperCase()} </ShadowText>
        <Box maxW='600px' bg='background.100' w='100%' flex={1} minH='0'>
          <InfiniteScrollList
            // basePath={basePath}
            list={list}
          />
        </Box>
      </VStack>
      <MobileDarkModeToggle />
    </NymnPage>
  )
}
