import { Box } from '@chakra-ui/react'
import { QuickSearch } from '@elanmc/elan-react'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { useContext, useMemo } from 'react'
import { useViewingAs } from '../../../contexts'
import { getSubmissionsList } from './list'

const GameList = () => {
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)
  const viewingAs = useViewingAs()
  const list = useMemo(() => getSubmissionsList(broadcasterId, viewingAs === 'admin'), [broadcasterId, viewingAs])
  return <QuickSearch list={list} footerProps={{ bg: 'background.200' }} />
}

export const GameJamVoting = () => (
  <Box bg='whiteAlpha.500' _dark={{ bg: 'blackAlpha.500' }} maxW='1000px' w='95%' h='100%'>
    <GameList />
  </Box>
)
