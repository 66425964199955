import { Broadcaster, WheelAuction } from '@elan-twitch/shared'
import {
  DBItem,
  useDocument, useFirebase, useQuery, WithId,
} from '@elanmc/elan-react'
import {
  getWheelAuctionDocPath,
} from 'backend/db'
import {
  collection,
  Firestore,
  orderBy, Query, query,
} from 'firebase/firestore'
import { useMemo } from 'react'
import { paletteOptions } from './constants'

export const usePalette = (auction: WheelAuction | null) => useMemo(() => {
  if (!auction?.palette) return paletteOptions[2]
  const p = auction.palette || '2'
  const paletteAsNumber = Number(p)
  return Number.isNaN(paletteAsNumber)
    ? paletteOptions[2]
    : paletteOptions[paletteAsNumber]
}, [auction])

export const useWheelAuction = (broadcaster: WithId<Broadcaster>) => {
  const path = useMemo(
    () => (broadcaster.pointsBankAccountConfig?.currentWheelAuctionId
      ? getWheelAuctionDocPath(
        broadcaster._id,
        broadcaster.pointsBankAccountConfig?.currentWheelAuctionId,
      )
      : null),
    [broadcaster],
  )
  return useDocument<WheelAuction>(path)
}

const getSavedWheelQuery = (db: Firestore, broadcaster: WithId<Broadcaster>) => query(
  collection(db, `broadcaster/${broadcaster._id}/wheelAuction`),
  orderBy('created_at', 'desc'),
) as Query<DBItem<WheelAuction>>

export const useSavedWheels = (broadcaster: WithId<Broadcaster>) => {
  const { db } = useFirebase()
  const q = useMemo(() => getSavedWheelQuery(db, broadcaster), [broadcaster, db])

  return useQuery(q)
}
