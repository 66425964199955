import {
  AutomaticPointsAuction,
  BROADCASTER,
  Broadcaster,
  GameJamSubmission,
  GAME_JAM,
  getBroadcasterAuctionDocPath,
  getBroadcasterAuctionsCollectionPath,
  getBroadcasterMovieListCollectionPath, getBroadcasterPointsBankCollectionPath, MovieListMovie,
  PERMISSIONS, PermissionsData,
  PointsBankAccount, POINTS_BANK,
  POLL, Poll, PREDICTION, Prediction, processData, RANDOM_DROPS, WheelAuction, WHEEL_AUCTION, WithId,
} from '@elan-twitch/shared'
import { DBItem, FieldMap } from '@elanmc/elan-react'
import {
  collection, CollectionReference, doc, DocumentReference, Firestore, setDoc,
} from 'firebase/firestore'

export const getBroadcasterRef = (db:Firestore, broadcasterId: string) => doc(
  db,
  `${BROADCASTER}/${broadcasterId}`,
) as DocumentReference<Broadcaster>

export const getBroadcasterRandomDropsCollection = (db: Firestore, broadcasterId: string) => collection(
  db,
  `${BROADCASTER}/${broadcasterId}/${RANDOM_DROPS}`,
) as CollectionReference<WithId<any>>

export const getBroadcasterPointsBankCollection = (db: Firestore, broadcasterId: string) => collection(
  db,
  getBroadcasterPointsBankCollectionPath(broadcasterId),
) as CollectionReference<PointsBankAccount>

export const getBroadcasterMovieListCollection = (db: Firestore, broadcasterId: string) => collection(
  db,
  getBroadcasterMovieListCollectionPath(broadcasterId),
) as CollectionReference<DBItem<MovieListMovie>>

export const getBroadcasterGameJamSubmissionsCollectionPath = (broadcasterId: string) => `${BROADCASTER}/${broadcasterId}/${GAME_JAM}`

export const getBroadcasterGameJamSubmissionsCollection = (db: Firestore, broadcasterId: string) => collection(
  db,
  getBroadcasterGameJamSubmissionsCollectionPath(broadcasterId),
) as CollectionReference<GameJamSubmission>

export const getBroadcasterPointsBankDocRef = (
  db: Firestore,
  broadcasterId: string,
  userName: string,
) => doc(
  db,
  `${BROADCASTER}/${broadcasterId}/${POINTS_BANK}/${userName.toLowerCase()}`,
) as DocumentReference<PointsBankAccount>

export const getBroadcasterAuctionsCollection = (db: Firestore, broadcasterId: string) => collection(
  db,
  getBroadcasterAuctionsCollectionPath(broadcasterId),
) as CollectionReference<AutomaticPointsAuction>

export const getBroadcasterAuctionDocRef = (
  db: Firestore,
  broadcasterId: string,
  auctionId: string,
) => doc(
  db,
  `${getBroadcasterAuctionDocPath(broadcasterId, auctionId)}`,
) as DocumentReference<AutomaticPointsAuction>

export const getWheelAuctionDocPath = (
  broadcasterId: string,
  timeStamp: string,
) => `${BROADCASTER}/${broadcasterId}/${WHEEL_AUCTION}/${timeStamp}`

export const getWheelAuctionDocRef = (
  db: Firestore,
  broadcasterId: string,
  timeStamp: string,
) => doc(
  db,
  getWheelAuctionDocPath(broadcasterId, timeStamp),
) as DocumentReference<WheelAuction>

export const getCurrentWheelAuctionDocPath = (
  broadcaster: WithId<Broadcaster>,
) => (broadcaster.pointsBankAccountConfig?.currentWheelAuctionId
  ? `${BROADCASTER}/${broadcaster._id}/${WHEEL_AUCTION}/${broadcaster.pointsBankAccountConfig?.currentWheelAuctionId}`
  : null)
export const getCurrentWheelAuctionDocRef = (
  db: Firestore,
  broadcaster: WithId<Broadcaster>,
) => {
  const path = getCurrentWheelAuctionDocPath(broadcaster)
  return path ? doc(db, path) as DocumentReference<WheelAuction> : null
}

export const getPollDocRef = (db: Firestore, broadcasterTwitchId: string) => doc(db, `${POLL}/${broadcasterTwitchId}`) as DocumentReference<Poll>

export const getPredictionDocRef = (db: Firestore, broadcasterTwitchId: string) => doc(
  db,
  `${PREDICTION}/${broadcasterTwitchId}`,
) as DocumentReference<Prediction>

export const getPermissionsDoc = (db: Firestore, id: string) => doc(db, PERMISSIONS, id || 'test') as DocumentReference<PermissionsData>
export const processAndUpdate = (
  field: FieldMap,
  ref: DocumentReference,
  path: string,
  data: any,
) => processData(field, async () => null, data).then((processed) => {
  const saved: Record<string, any> = path ? { [path]: processed } : processed

  return setDoc(ref, saved, { merge: true })
})
