import {
  Field, FieldMap, IFormElement, isField, isListField, ListField,
} from '@elanmc/elan-react'
import {
  OnUploadsProgress,
  UploadFileFunc,
  UploadsProgressEvent,
} from '../types'

const processFieldData = async (
  field: Field,
  data: any | undefined,
  propPath: string | null,
  uploadFile?: UploadFileFunc,
  storagePath?: string | null,
  onUploadsProgress?: OnUploadsProgress,
  index?: number,
): Promise<any> => {
  if (data === undefined || data === null) return null
  const asNum = parseFloat(data || '')
  switch (field._type) {
    case 'file':
      if (!uploadFile) throw new Error('uploadFile function is required for file fields')
      if (!storagePath) throw new Error('storagePath is required for file fields')
      if (!onUploadsProgress) throw new Error('onUploadProgress is required for file fields')
      return uploadFile(
        `${storagePath}${propPath ? `/${propPath}` : ''}/${data.filename}`,
        data,
        (progress) => onUploadsProgress({
          [propPath || 'value']: {
            ...progress,
            label: `${field.placeholder}${
              index !== undefined ? ` ${index}` : ''
            }`,
          },
        }),
      )
    case 'boolean':
      return data || false
    case 'currency':
    case 'number':
      if (Number.isNaN(asNum)) return null
      return asNum
    default:
      return typeof data === 'number' ? data : data || null
  }
}

const processRecordFieldData = async (
  field: ListField,
  data: any | undefined,
  uploadFile?: UploadFileFunc,
  storagePath?: string | null,
  onUploadsProgress?: OnUploadsProgress,
): Promise<any> => {
  if (data === undefined || data === null) return []
  let uploads: UploadsProgressEvent = {}
  const onUploadProgress = (progress: UploadsProgressEvent) => {
    uploads = { ...uploads, ...progress }
    if (onUploadsProgress) onUploadsProgress(uploads)
  }
  const { itemField } = field
  return Promise.all(
    data.map((item: any, idx: number) => processData(
      itemField,
      item,
      uploadFile,
      storagePath,
      onUploadProgress,
      undefined,
      idx,
    )),
  )
}

export const processFieldMapData = async (
  field: FieldMap,
  data: any | undefined,
  uploadFile?: UploadFileFunc,
  storagePath?: string | null,
  onUploadsProgress?: OnUploadsProgress,
): Promise<Record<string, any>> => {
  const entries = Object.entries(field.children)
  let uploads: UploadsProgressEvent = {}
  const onUploadProgress = (progress: UploadsProgressEvent) => {
    uploads = { ...uploads, ...progress }
    if (onUploadsProgress) onUploadsProgress(uploads)
  }
  const processedData = await Promise.all(
    entries.map(async ([fieldKey, child]) => ({
      fieldKey,
      data: await processData(
        child,
        data?.[fieldKey],
        uploadFile,
        storagePath,
        onUploadProgress,
      ),
    })),
  )
  return processedData.reduce(
    (acc, { fieldKey, data: processed }) => ({
      ...acc,
      [fieldKey]: processed,
    }),
    {} as Record<string, any>,
  )
}

export const processData = async (
  field: IFormElement,
  data: any | undefined,
  uploadFile?: UploadFileFunc,
  storagePath?: string | null,
  onUploadsProgress?: OnUploadsProgress,
  propPath?: string,
  index?: number,
) => {
  let uploads: UploadsProgressEvent = {}
  const onUploadProgress = (progress: UploadsProgressEvent) => {
    uploads = { ...uploads, ...progress }
    if (onUploadsProgress) onUploadsProgress(uploads)
  }

  const onComplete = () => {
    onUploadsProgress?.({})
  }
  if (isField(field)) {
    return processFieldData(
      field,
      data,
      propPath || null,
      uploadFile,
      storagePath,
      onUploadProgress,
      index,
    ).then((res) => {
      onComplete()
      return res
    })
  }
  if (isListField(field)) {
    return processRecordFieldData(
      field,
      data,
      uploadFile,
      storagePath,
      onUploadProgress,
    ).then((res) => {
      onComplete()
      return res
    })
  }
  return processFieldMapData(
    field,
    data,
    uploadFile,
    storagePath,
    onUploadProgress,
  ).then((res) => {
    onComplete()
    return res
  })
}

export const recordToArray = (record?: Record<string, any>) => Object.entries(record || {}).map(([_id, val]) => ({ ...val, _id }))
export const arrayToRecord = <T extends { _id: string }>(
  arr?: Array<{ _id: string } & T>,
): Record<string, T> => (arr || []).reduce(
    (acc, curr) => ({
      ...acc,
      [curr._id]: curr,
    }),
    {} as Record<string, any>,
  )

export const toDateTime = (date: number, time: string) => {
  const dateString = new Date(date).toISOString().split('T')[0]
  return new Date(`${dateString}T${time}:00.000`).getTime()
}

export const generateId = (existing?: Array<string>) => {
  let newId = Math.random().toString(36).substring(2, 9)
  while (existing?.includes(newId)) {
    newId = Math.random().toString(36).substring(2, 9)
  }
  return newId
}

export const makePlural = (str: string) => {
  if (str.endsWith('s')) return `${str}es`
  if (str.endsWith('y')) return `${str.slice(0, -1)}ies`
  if (str.endsWith('x')) return `${str}es`
  return `${str}s`
}

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const toSearchString = (inStr: string): string => inStr.toLowerCase().replace(/[^\w\d\s|]/g, '')
export const getRowBackground = (index?: number) => {
  if (index === undefined) return 'transparent'
  return index % 2 === 0 ? 'rgb(245,245,245)' : 'white'
}

export const truncateNumber = (num: number) => {
  if (num < 1000) return num
  if (num < 1000000) return `${(num / 1000).toFixed(2)}K`
  return `${(num / 1000000).toFixed(2)}M`
}
export const formatNumber = (num: number) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const toFixedRounded = (num: number, decimalPlaces: number = 2) => {
  const factor = 10 ** decimalPlaces
  return Math.round(num * factor) / factor
}

export const toPlural = (str: string | undefined) => {
  if (!str) return ''
  if (str.endsWith('s')) return str
  if (str.endsWith('y')) return `${str.slice(0, -1)}ies`
  if (str.endsWith('x')) return `${str}es`
  return `${str}s`
}

export const toLowercase = (str: string) => str.toLowerCase()
