import {
  Button, Center, Collapse, Flex, Text, useToast,
} from '@chakra-ui/react'
import { gradients, gradientToCssGradientProp } from '@elan-twitch/shared'
import { useFirebase } from '@elanmc/elan-react'
import { signInWithCustomToken } from 'firebase/auth'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getThirdPartyAuthToken } from '../backend/functions'
import { ContentBox } from '../components/shared/ContentBox'
import { Loading } from '../components/shared/Loading'
import { useTwitchUserSignIn } from '../hooks/useTwitchLogin'

export const AuthRedirect = () => {
  const { hash, search } = useLocation()
  const toast = useToast()
  const { auth } = useFirebase()
  const { functions } = useFirebase()
  const [error, setError] = useState<string | null>(null)
  const { handleSignIn: retry, isSigningIn } = useTwitchUserSignIn()
  const { code, state } = useMemo(() => {
    const params = new URLSearchParams(search || `?${hash.slice(1)}`)
    return {
      code: params.get('code'),
      state: params.get('state'),
    }
  }, [hash, search])
  const navigate = useNavigate()

  useEffect(() => {
    if (!state) {
      toast({
        title: 'Error',
        description: 'No state found',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }
    if (!code) {
      toast({
        title: 'Error',
        description: 'No access token found',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return
    }
    const fetch = async () => {
      try {
        const { data: { token, pathname } } = await getThirdPartyAuthToken(functions)({
          code,
          state,
        })
        await signInWithCustomToken(auth, token)
        navigate(pathname)
      } catch (err: any) {
        toast({
          title: 'Error',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        setError(err.message)
      }
    }
    fetch()
  }, [code, state, toast, navigate, auth, functions])

  return (
    <Center bg={gradientToCssGradientProp(gradients.nymn)} w='100%' h='100%'>
      <Flex gap={2} flexFlow='column' maxW='100%' borderRadius={6} boxShadow='1px 1px 4px #00000066' w='500px'>
        <Loading text='Logging in with Twitch...' />
        <Collapse in={!!error} style={{ width: '100%' }}>
          <ContentBox flexFlow='column' borderRadius={4} px={3} py={1}>
            <Text px={1} py={1} color='red.600'>{error || ' '}</Text>
            <Flex gap={2} w='100%' align='center'>
              <Button pointerEvents='auto' flex={1} onClick={() => setError(null)}>Close</Button>
              <Button pointerEvents='auto' flex={1} isLoading={isSigningIn} onClick={retry}>
                Retry
              </Button>
            </Flex>
          </ContentBox>
        </Collapse>
      </Flex>
    </Center>
  )
}
