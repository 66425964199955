import {
  Box, Flex, Tooltip, useToast,
} from '@chakra-ui/react'
import { GameVote, VoteOnGameArgs } from '@elan-twitch/shared'
import { LoadingOverlay, useFirebase } from '@elanmc/elan-react'
import { Functions, httpsCallable } from 'firebase/functions'
import { useCallback, useContext, useState } from 'react'
import { BroadcasterContext } from '../../../components/App/Broadcasters/context'
import { StarRating } from '../../../components/StarRating'
import { useViewingAs } from '../../../contexts'
import { useTwitchUserSignIn } from '../../../hooks/useTwitchLogin'
import { useUser } from '../../../store/useUser'
import { GameViewContext } from './context'
import { useMyGameJamVote } from './hooks'

const voteOnGame = (functions: Functions) => httpsCallable<VoteOnGameArgs, void>(functions, 'voteOnGame')

export const VoteOnGame = ({
  // refetchListOnSuccess,
  refetchItemOnSuccess,
}: {
  refetchItemOnSuccess?: boolean
}) => {
  const authUser = useUser((s) => s.user)
  const { gameId: id, game } = useContext(GameViewContext)
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)
  // const {
  //   data: { refetchItem: refetchOnList },
  // } = useContext(InfiniteScrollListContext)
  const role = useViewingAs()
  const { refetch: refetchItem } = useContext(GameViewContext)
  const { data: myVote } = useMyGameJamVote(id)
  const [loadingVote, setLoadingVote] = useState(false)
  const toast = useToast()
  const { functions } = useFirebase()
  const handleVote = useCallback(
    async (vote: GameVote['score'] | null) => {
      setLoadingVote(true)
      try {
        await voteOnGame(functions)({ id, vote, broadcasterId })
        if (refetchItemOnSuccess && refetchItem) refetchItem()
      } catch (err: any) {
        toast({
          title: 'Failed to vote on game',
          description: err.message,
          status: 'error',
        })
      }
      setLoadingVote(false)
    },
    [broadcasterId, id, toast, functions, refetchItem, refetchItemOnSuccess],
  )

  const { isSigningIn, handleSignIn } = useTwitchUserSignIn()
  const loading = loadingVote || isSigningIn
  const body = (
    <Flex
      align='center'
      position='relative'
      borderRadius='full'
      px={2}
      onClick={
        !authUser
          ? (e) => {
            e.stopPropagation()
            handleSignIn()
          }
          : undefined
      }
      flexFlow='column'
      gap={2}
    >
      <Box transition={`opacity 300ms ease ${loading ? 0 : 300}ms`} opacity={loading ? 0 : 1}>
        <StarRating admin={role === 'admin'} height={26} maxScore={3} myVote={myVote} onVote={authUser ? handleVote : undefined} item={game} />
      </Box>
      <LoadingOverlay bg='transparent' isLoading={isSigningIn || loadingVote} />
    </Flex>
  )

  return (
    <Flex gap={2} align='center'>
      {authUser ? (
        body
      ) : (
        <Tooltip hasArrow label='Sign in with Twitch to vote' placement='top'>
          {body}
        </Tooltip>
      )}
    </Flex>
  )
}

export const GameActivity = () => <VoteOnGame refetchItemOnSuccess />
