import {
  BROADCASTER, DOWNVOTES, GAME_JAM, MOVIE_LIST, POINTS_BANK, UPVOTES, VOTES,
} from '../../constants/db'

export const getBroadcasterPointsBankCollectionPath = (broadcasterId: string) => `${BROADCASTER}/${broadcasterId}/${POINTS_BANK}`
export const getBroadcasterMovieListCollectionPath = (broadcasterId: string) => `${BROADCASTER}/${broadcasterId}/${MOVIE_LIST}`
export const getMovieListMoviePath = (broadcasterId: string, movieId: string) => `${getBroadcasterMovieListCollectionPath(broadcasterId)}/${movieId}`

export const getUpvoteDocPath = (broadcasterId: string, movieId: string, userId: string) => `${getBroadcasterMovieListCollectionPath(broadcasterId)}/${movieId}/${UPVOTES}/${userId}`
export const getDownvoteDocPath = (broadcasterId: string, movieId: string, userId: string) => `${getBroadcasterMovieListCollectionPath(broadcasterId)}/${movieId}/${DOWNVOTES}/${userId}`

export const getGameJamVoteDocPath = (broadcasterId: string, submissionId: string, userId: string) => `${BROADCASTER}/${broadcasterId}/${GAME_JAM}/${submissionId}/${VOTES}/${userId}`
