import { Flex, Text, useColorModeValue } from '@chakra-ui/react'
import { useFirebase } from '@elanmc/elan-react'
import { doc } from 'firebase/firestore'
import { useMemo } from 'react'
import { ContentBox } from '../../shared/ContentBox'
import { EnableSwitch } from '../../shared/EnableSwitch'
import { usePointsBankConfig } from './hooks'
import { RandomDropsConfig } from './RandomDrops/RandomDropsConfig'

export const PointsConfig = () => {
  const { config, path } = usePointsBankConfig()
  const { db } = useFirebase()
  const configDoc = useMemo(() => (path ? doc(db, path) : null), [path, db])

  const { enabled } = config || {}

  const formBg = useColorModeValue('blackAlpha.400', 'whiteAlpha.500')
  return (
    <Flex h='100%' w='100%'>
      <Flex bg={formBg} boxShadow='3px 0 4px #00000077' p={4} h='100%'>
        <ContentBox p={0} overflowY='auto' flexFlow='column' maxH='100%' width={455}>
          <Flex px={2} pt={3} w='100%' align='center'>
            <Text pl={2} flex={1} fontSize='lg' opacity={0.8}>
              POINTS BANK
            </Text>
            <EnableSwitch enabled={enabled} docRef={configDoc} propPath='enabled' />
          </Flex>
          <RandomDropsConfig />
        </ContentBox>
      </Flex>
    </Flex>
  )
}
