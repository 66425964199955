import {
  Box,
  Button,
  DarkMode,
  Flex, Link, ListItem, Text, TextProps, UnorderedList, useToast,
} from '@chakra-ui/react'
import { BROADCASTER, GameJamSubmission, GAME_JAM } from '@elan-twitch/shared'
import {
  ContentBox, DefaultModal, ExpandOnMount, Form, gradients,
  gradientToCssGradientProp, useAuth, useBorderColor, useDocument,
  useFirebase,
} from '@elanmc/elan-react'
import { AnyObject } from 'final-form'
import { collection, doc, updateDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import {
  PropsWithChildren,
  useCallback, useContext, useMemo, useState,
} from 'react'
import { BroadcasterContext } from '../../../components/App/Broadcasters/context'
import { PulsingCircle } from '../../../components/shared/Animation/PulsingCirlce'
import { useIsChannelAdmin } from '../../../hooks/useIsChannelAdmin'
import { submissionField } from './field'
import { SubmissionPreview } from './SubmissionPreview'

const ItemHeader = (props: TextProps) => (
  // @ts-ignore
  <Text fontSize='lg' fontWeight={600} opacity={0.7} {...props} />
)

const LabelText = ({ label, children }: { label: string; children: string }) => (
  <ListItem>
    <Text verticalAlign='center'>
      <Text mr={1.5} as='span' fontWeight={600} fontSize='sm'>
        {label}:
      </Text>
      <span>{children}</span>
    </Text>
  </ListItem>
)

const InfoItem = ({ title, children }: PropsWithChildren<{ title: string }>) => {
  const borderColor = useBorderColor()
  return (
    <ContentBox py={2} flexFlow='column' w='100%'>
      <Flex px={3} borderColor={borderColor} w='100%'>
        <ItemHeader>{title}</ItemHeader>
      </Flex>
      <Flex px={4}>{children}</Flex>
    </ContentBox>
  )
}

export const GameJamContent = () => (
  <>
    <InfoItem title='Event Overview'>
      <UnorderedList>
        <LabelText label='Theme'>Horror</LabelText>
        <LabelText label='Prize Pool'>$3,000</LabelText>
        <LabelText label='Submission Deadline'>September 30</LabelText>
        <LabelText label='Eligibility'>Open to individuals and teams</LabelText>
        <LabelText label='Requirement'>Must be a NymN follower since before August 1</LabelText>
      </UnorderedList>
    </InfoItem>
    <InfoItem title='Rules and Guidelines'>
      <UnorderedList>
        <ListItem>All game engines and tools are allowed</ListItem>
        <ListItem>Original content is encouraged; no blatant asset recycling</ListItem>
        <ListItem>Participants may use content from NymN's stream, including NymN's music</ListItem>
      </UnorderedList>
    </InfoItem>
    <InfoItem title='Submission and Judging'>
      <UnorderedList>
        <ListItem>The winner will be selected through a combination of NymN's judgement and a chat vote.</ListItem>
      </UnorderedList>
    </InfoItem>
    <InfoItem title='Game Showcase'>
      <UnorderedList>
        <ListItem>
          Games will be played live on{' '}
          <Link href='https://twitch.tv/nymn' target='_blank'>
            twitch.tv/nymn
          </Link>{' '}
          starting October 1st
        </ListItem>
        <ListItem>NymN will attempt to complete all games (within reasonable time limits)</ListItem>
      </UnorderedList>
    </InfoItem>
    <InfoItem title='Community and Support'>
      <UnorderedList>
        <ListItem>
          Join the discussion in our official Discord channel: #gamejam on{' '}
          <Link href='https://discord.gg/nymn'>discord.gg/nymn</Link>
        </ListItem>
      </UnorderedList>
    </InfoItem>
  </>
)

const ToggleSubmissions = () => {
  const {
    broadcaster: { _id, gameJamSubmissionsOpen },
  } = useContext(BroadcasterContext)
  const { db } = useFirebase()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const handleToggle = useCallback(async () => {
    setIsLoading(true)
    const docRef = doc(db, `${BROADCASTER}/${_id}`)
    try {
      await updateDoc(docRef, 'gameJamSubmissionsOpen', !gameJamSubmissionsOpen)
    } catch (err: any) {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
    setIsLoading(false)
  }, [gameJamSubmissionsOpen, _id, db, toast])
  return (
    <Button bg='blackAlpha.300' onClick={handleToggle} isLoading={isLoading} size='sm'>
      {gameJamSubmissionsOpen ? 'Close Submissions' : 'Open Submissions'}
    </Button>
  )
}

const buttonBg = gradientToCssGradientProp(gradients.twitch, 340)
const SubmissionsStatus = () => {
  const isAdmin = useIsChannelAdmin()
  const { broadcaster } = useContext(BroadcasterContext)
  const { gameJamSubmissionsOpen } = broadcaster || {}

  return (
    <ContentBox w='100%'>
      <Flex minW='0' gap={2} flex={1} align='center' justify='center' w='100%'>
        <PulsingCircle active={!!gameJamSubmissionsOpen} />
        <Text lineHeight={1} fontSize='lg' fontWeight={600} opacity={0.8}>
          Submissions are {gameJamSubmissionsOpen ? 'open!' : 'closed'}
        </Text>
      </Flex>
      {isAdmin ? <ToggleSubmissions /> : null}
    </ContentBox>
  )
}

export const GameJamInfo = () => {
  const me = useAuth((s) => s.user)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { db, functions } = useFirebase()
  const {
    broadcaster: { _id: broadcasterId, gameJamSubmissionsOpen },
  } = useContext(BroadcasterContext)
  const submissionRef = useMemo(() => {
    if (!me) return null
    return doc(collection(db, `${BROADCASTER}/${broadcasterId}/${GAME_JAM}`), me.uid)
  }, [db, broadcasterId, me])
  const onSubmit = useCallback(
    async (d: AnyObject) => {
      if (!isSubmitting || !submissionRef) throw new Error('Internal error')
      const submitFunction = httpsCallable(functions, 'makeGameJamSubmission')
      await submitFunction(d)
      return undefined
    },
    [isSubmitting, submissionRef, functions],
  )
  const { data: mySubmission } = useDocument<GameJamSubmission>(submissionRef?.path || null)
  const storagePath = useMemo(() => {
    if (!me) return null
    return `nymn/${GAME_JAM}/${me.uid}`
  }, [me])
  const buttonText = useMemo(() => {
    if (!gameJamSubmissionsOpen) return 'Submissions Closed'
    if (!me) return 'Sign in to Submit'
    if (mySubmission) return 'Edit Your Submission'
    return 'Submit Your Game'
  }, [me, mySubmission, gameJamSubmissionsOpen])
  return (
          <Flex flexFlow='column' gap={2} w='95%' maxW='800px'>
            <SubmissionsStatus />
            <GameJamContent />
            {mySubmission ? (
              <ExpandOnMount>
                <Box bg='blackAlpha.500' w='100%' borderRadius={6}>
                  <Box color='white' w='100%' h={['300px', '300px', '170px']}>
                    <DarkMode>
                      <SubmissionPreview isMine bg='transparent' submission={mySubmission} />
                    </DarkMode>
                  </Box>
                </Box>
              </ExpandOnMount>
            ) : null}
            <Button
              onClick={() => setIsSubmitting(true)}
              fontFamily='Bakbak One'
              bg={me && gameJamSubmissionsOpen ? buttonBg : undefined}
              pointerEvents={me && gameJamSubmissionsOpen ? 'auto' : 'none'}
              fontSize='xl'
              fontWeight={500}
              _dark={{ bg: me && gameJamSubmissionsOpen ? buttonBg : undefined }}
              _hover={{
                bg: me ? buttonBg : undefined,
              }}
              size='lg'
            >
              {buttonText}
            </Button>
      {storagePath ? (
        <DefaultModal isOpen={isSubmitting} onClose={() => setIsSubmitting(false)}>
          <Box px={2} py={3}>
            <Form value={mySubmission} onSubmit={onSubmit} storagePath={storagePath} field={submissionField} />
          </Box>
        </DefaultModal>
      ) : null}
          </Flex>
  )
}
