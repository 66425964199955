import { MinusIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Image,
  Text,
  Tooltip,
  useColorMode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import {
  getDateString, getMovieListMoviePath, MovieListMovie, VoteOnMovieArgs,
} from '@elan-twitch/shared'
import { useFirebase } from '@elanmc/elan-react'
import { doc, updateDoc } from 'firebase/firestore'
import { Functions, httpsCallable } from 'firebase/functions'
import {
  useCallback, useContext, useMemo, useState,
} from 'react'
import { useIsChannelAdmin } from '../../../../hooks/useIsChannelAdmin'
import { useTwitchUserSignIn } from '../../../../hooks/useTwitchLogin'
import upCarrotIcon from '../../../../icons/up-carrot.svg'
import { useUser } from '../../../../store/useUser'
import { BroadcasterContext } from '../../../App/Broadcasters/context'
import { ContentBox } from '../../../shared/ContentBox'
import { MovieViewContext } from './context'
import { useMyVote } from './hooks'
import { MovieNominatedStatus } from './MovieNominatedStatus'

const nominateMovie = (functions: Functions) => httpsCallable<{ broadcasterId: string; id: string }, void>(functions, 'nominateMovie')
const voteOnMovie = (functions: Functions) => httpsCallable<VoteOnMovieArgs, void>(functions, 'voteOnMovie')

const NominateMovie = () => {
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)
  const { refetch, movieId } = useContext(MovieViewContext)
  const user = useUser((s) => s.user)
  const { functions } = useFirebase()
  // const {
  //   data: { refetch: refetchList },
  // } = useContext(InfiniteScrollListContext)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const handleNominate = useCallback(async () => {
    const uid = user?.uid
    if (!uid) {
      toast({
        title: 'Sign in to nominate',
        description: 'You must be signed in to nominate a movie',
        status: 'error',
      })
      return
    }
    setIsLoading(true)
    try {
      await nominateMovie(functions)({ id: movieId, broadcasterId })
      if (refetch) refetch()
      // refetchList()
    } catch (err: any) {
      toast({
        title: 'Failed to nominate movie',
        description: err.message,
        status: 'error',
      })
    }
    setIsLoading(false)
  }, [broadcasterId, movieId, toast, refetch, user, functions])

  const body = (
    <Box>
      <Button
        pointerEvents={user ? 'auto' : 'none'}
        onClick={handleNominate}
        isLoading={isLoading}
        variant='solid'
        alignItems='center'
        fontSize='sm'
        gap={1}
      >
        NOMINATE
      </Button>
    </Box>
  )
  return user ? (
    body
  ) : (
    <Tooltip hasArrow label='Sign in with Twitch to nominate' placement='top'>
      {body}
    </Tooltip>
  )
}

export const MovieWatchedStatus = ({ timestamp, size = 'md' }: { timestamp: number | null; size?: 'sm' | 'md' }) => (
  <Badge bg={timestamp ? 'green.500' : '#777'} fontSize={size}>
    {timestamp ? `Marked watched ${getDateString(timestamp)}` : 'Not watched'}
  </Badge>
)
const MovieWatched = ({ movie }: { movie: MovieListMovie }) => {
  const { marked_watched_on } = movie
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)
  // const {
  //   data: { refetch },
  // } = useContext(InfiniteScrollListContext)
  const { movieId } = useContext(MovieViewContext)
  const user = useUser((s) => s.user)
  const { db } = useFirebase()
  const isAdmin = useIsChannelAdmin()
  const toast = useToast()
  const [loadingToggle, setLoadingToggle] = useState(false)
  const handleToggleWatched = useCallback(async () => {
    setLoadingToggle(true)
    try {
      await updateDoc(doc(db, getMovieListMoviePath(broadcasterId, movieId)), {
        marked_watched_on: marked_watched_on ? null : Date.now(),
        marked_watched_by: marked_watched_on ? null : user?.uid || 'unknown',
        watched: !marked_watched_on,
      })
      // refetch()
    } catch (err: any) {
      toast({
        title: 'Failed to toggle watched status',
        description: err.message,
        status: 'error',
      })
    }
    setLoadingToggle(false)
  }, [broadcasterId, marked_watched_on, db, movieId, toast, user])
  return (
    <Flex gap={2} minH='100%' flexFlow='column' justify='center'>
      {isAdmin ? (
        <Button size='sm' onClick={handleToggleWatched} isLoading={loadingToggle}>
          {marked_watched_on ? 'MARK AS UNWATCHED' : 'MARK AS WATCHED'}
        </Button>
      ) : null}
      {!isAdmin || marked_watched_on ? <MovieWatchedStatus size='sm' timestamp={marked_watched_on} /> : null}
    </Flex>
  )
}

export const MovieVotesView = ({ movie }: { movie: MovieListMovie }) => {
  const { vote_score } = movie
  const { colorMode } = useColorMode()
  // const iconFilter = useColorModeValue('invert(0)', 'invert(1)')

  const scoreColor = useMemo(() => {
    if (vote_score === 0) return colorMode === 'dark' ? '#ababab' : '#777'
    return vote_score > 0 ? 'green.600' : 'red.600'
  }, [colorMode, vote_score])
  return (
    <Flex gap={4}>
      {/* <Flex pr={2} opacity={0.5} align='center'>
        <Image filter={iconFilter} src={personIcon} alt='Person icon' h={5} />
        <Text lineHeight={1}>{vote_count}</Text>
      </Flex> */}
      <Flex flexFlow='column' align='center'>
        {/* <Center bg={scoreColor} borderRadius='full' w='32px' h='32px'> */}
        <Text lineHeight={1} color={scoreColor} fontWeight={600} fontFamily='Bebas Neue' fontSize='3xl'>
          {vote_score > 0 ? '+' : ''}
          {vote_score}
        </Text>
      </Flex>
    </Flex>
  )
}

export const VoteOnMovie = ({
  movie,
  // refetchListOnSuccess,
  refetchItemOnSuccess,
}: {
  movie: MovieListMovie
  refetchListOnSuccess?: boolean
  refetchItemOnSuccess?: boolean
}) => {
  const authUser = useUser((s) => s.user)
  const { movieId: id } = useContext(MovieViewContext)
  const {
    broadcaster: { _id: broadcasterId },
  } = useContext(BroadcasterContext)
  // const {
  //   data: { refetchItem: refetchOnList },
  // } = useContext(InfiniteScrollListContext)
  const { refetch: refetchItem } = useContext(MovieViewContext)
  const myVote = useMyVote(id)
  const buttonBg = useColorModeValue('blackAlpha.300', 'whiteAlpha.300')
  const [loadingVote, setLoadingVote] = useState<'up' | 'down' | null>(null)
  const toast = useToast()
  const { functions } = useFirebase()
  const [upHovered, setUpHovered] = useState(false)
  const handleVote = useCallback(
    async (vote: 'up' | 'down' | 'remove') => {
      if (vote === 'remove') setLoadingVote(myVote === 1 ? 'up' : 'down')
      else setLoadingVote(vote)
      try {
        await voteOnMovie(functions)({ id, vote, broadcasterId })
        // if (refetchListOnSuccess) refetchOnList(id)
        if (refetchItemOnSuccess && refetchItem) refetchItem()
      } catch (err: any) {
        toast({
          title: 'Failed to vote on movie',
          description: err.message,
          status: 'error',
        })
      }
      setLoadingVote(null)
    },
    [myVote, broadcasterId, id, toast, functions, refetchItem, refetchItemOnSuccess],
  )

  const { isSigningIn, handleSignIn } = useTwitchUserSignIn()

  const body = (
    <Flex
      align='center'
      borderRadius='full'
      onClick={
        !authUser
          ? (e) => {
            e.stopPropagation()
            handleSignIn()
          }
          : undefined
      }
      flexFlow='column'
      gap={2}
    >
      <IconButton
        pointerEvents={!authUser ? 'none' : 'auto'}
        bg={myVote === 1 ? 'green.500' : buttonBg}
        onClick={(e) => {
          e.stopPropagation()
          handleVote(myVote === 1 ? 'remove' : 'up')
        }}
        variant='unstyled'
        display='flex'
        opacity={authUser ? 1 : 0.7}
        onMouseEnter={() => setUpHovered(true)}
        onMouseLeave={() => setUpHovered(false)}
        _hover={{ bg: 'green.300' }}
        borderRadius='full'
        m={0}
        aria-label='Upvote'
        isLoading={isSigningIn || loadingVote === 'up'}
        icon={
          myVote === 1 && upHovered ? (
            <MinusIcon filter='drop-shadow(1px 1px 3px #000000aa)' color='white' />
          ) : (
            <Image
              position='relative'
              filter='invert(100%) drop-shadow(1px 1px 3px #000000aa)'
              src={upCarrotIcon}
              w={6}
            />
          )
        }
      />
      {/* <IconButton
        pointerEvents={!authUser ? 'none' : 'auto'}
        bg={myVote === -1 ? 'red.500' : buttonBg}
        borderRadius='full'
        opacity={authUser ? 1 : 0.5}
        aria-label='Downvote'
        variant='unstyled'
        display='flex'
        m={0}
        _hover={{ bg: myVote === -1 ? buttonBg : 'red.300' }}
        isLoading={isSigningIn || loadingVote === 'down'}
        onClick={(e) => {
          e.stopPropagation()
          handleVote(myVote === -1 ? 'remove' : 'down')
        }}
        icon={
          myVote === -1 ? (
            <MinusIcon />
          ) : (
          <Image
            position='relative'
            top='1px'
            transform='scaleY(-1)'
            filter='invert(100%) drop-shadow(1px 1px 3px #000000aa)'
            src={upCarrotIcon}
            w={6}
          />
          )
        }
      /> */}
    </Flex>
  )

  return (
    <Flex gap={4} align='center'>
      <MovieVotesView movie={movie} />
      {authUser ? (
        body
      ) : (
        <Tooltip hasArrow label='Sign in with Twitch to vote' placement='top'>
          {body}
        </Tooltip>
      )}
    </Flex>
  )
}

const NominateOrVote = ({ movie }: { movie: MovieListMovie }) => {
  const { nominatedOn } = movie
  if (!nominatedOn) {
    return <NominateMovie />
  }

  return <VoteOnMovie refetchListOnSuccess movie={movie} />
}

export const MovieActivity = ({ movie }: { movie: MovieListMovie }) => (
  <ContentBox px={4} w='100%'>
    <Flex w='100%'>
      <Center>
        <Box>
          <Flex flexFlow='column' gap={2}>
            <Flex>
              <MovieWatched movie={movie} />
            </Flex>
            <MovieNominatedStatus movie={movie} />
          </Flex>
        </Box>
      </Center>
      <Flex ml='auto'>
        <NominateOrVote movie={movie} />
      </Flex>
    </Flex>
  </ContentBox>
)
