import { Flex } from '@chakra-ui/react'
import { useScreen } from '@elanmc/elan-react'
import useResizeObserver from '@react-hook/resize-observer'
import {
  useCallback, useMemo, useRef, useState,
} from 'react'
import { LiveChatView } from '../../../components/Widgets/Chat/LiveChatView'
import { MovieListPreview } from '../../../components/Widgets/MovieList/MovieListPreview'
import { StreamView } from '../../../components/Widgets/Stream/StreamView'
import { FeatherProvider } from '../../../feather/provider'
import { useMounted } from '../../../hooks/useMounted'
import { GameJamPreview } from '../GameJam/GameJamPreview'
import { NymnPage } from '../NymnPage'
import { FridayReviewsPreview } from './FridayReviewsPreview'

const MoviesPanel = () => {
  const mounted = useMounted(350)
  return (
    <Flex
      gap={2}
      px={2}
      pb={2}
      opacity={mounted ? 1 : 0}
      transition='opacity 400ms'
      w='100%'
      direction={['column', 'column', 'row']}
    >
      <MovieListPreview />
    </Flex>
  )
}

const ContestsAndReviews = () => (
  <Flex w='100%' flexFlow='column' gap={2}>
    {/* <ContestsPreview /> */}
    <GameJamPreview />
    <FridayReviewsPreview />
  </Flex>
)

const aspectRatio = 16 / 9
const maxPlayerHeight = 700
export const NymnHomePage = () => {
  const width = useScreen((s) => s.width)
  //
  const [mainSize, setMainSize] = useState({ width: 0, height: 0 })
  const mainRef = useRef<HTMLDivElement>(null)
  const onMainResize = useCallback((e: ResizeObserverEntry) => {
    const rect = e.target.getBoundingClientRect()
    setMainSize({ width: rect.width, height: rect.height })
  }, [])
  useResizeObserver(mainRef, onMainResize)
  const mobileLayout = useMemo(() => width < 769, [width])
  // fill (contain) the video player in the main container - 16:9 aspect ratio
  const playerSize = useMemo(() => {
    if (mobileLayout) {
      return { width, height: width / aspectRatio }
    }
    const mainAspect = mainSize.width / mainSize.height
    let w = Math.min(mainSize.width, maxPlayerHeight * aspectRatio)
    let h = Math.min(maxPlayerHeight, mainSize.height)
    if (mainAspect > aspectRatio) {
      w = h * aspectRatio
    } else {
      h = w / aspectRatio
    }
    return { width: Math.floor(w), height: Math.floor(h) }
  }, [mainSize, width, mobileLayout])

  const { hasRightSidebar, hasLeftSidebar } = useMemo(() => {
    if (mobileLayout) return { hasRightSidebar: false, hasLeftSidebar: false }
    const playerMargin = width - playerSize.width
    return { hasRightSidebar: playerMargin > 500, hasLeftSidebar: playerMargin > 300 }
  }, [width, playerSize, mobileLayout])
  const mounted = useMounted(500)
  const viewerMounted = useMounted(1000)

  return (
    <FeatherProvider>
      <NymnPage>
        <Flex w='100%' h='100%' flexFlow='column'>
          <Flex
            ref={mainRef}
            height={['auto', 'auto', 'unset']}
            flex={['unset', 'unset', 1]}
            minH='0'
            w='100%'
            opacity={mounted ? 1 : 0}
            transition='opacity 400ms'
            pb={2}
            flexFlow={['column', 'column', 'row']}
            justify='center'
            align='center'
          >
            {hasLeftSidebar && (
              <Flex maxW={['400px', '400px', '400px', '500px']} minW='0' p={2} flex={1} h='100%'>
                {/* <Links /> */}
                <ContestsAndReviews />
              </Flex>
            )}
            <Flex
              opacity={viewerMounted ? 1 : 0}
              transition='opacity 500ms'
              align='center'
              style={{ overflow: 'hidden' }}
              overflow='hidden'
              width={`${playerSize.width}px`}
              height={`${playerSize.height}px`}
            >
              <StreamView width={playerSize.width} height={playerSize.height - 24} />
            </Flex>
            {hasRightSidebar && (
              <Flex color='white' minW='0' flex={1} px={3} py={2} h='100%'>
                <LiveChatView borderRadius={6} w='100%' h='100%' bg='#18181b99' />
                {/* <StreamChatView height='100%' width='100%' /> */}
              </Flex>
            )}
            {mobileLayout ? (
              <Flex w='100%' px={2} pb={2}>
                <ContestsAndReviews />
              </Flex>
            ) : null}
          </Flex>
          <MoviesPanel />
          {/* Footer */}
        </Flex>
      </NymnPage>
    </FeatherProvider>
  )
}
