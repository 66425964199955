import {
  FC, useContext, useEffect, useMemo, useState,
} from 'react'

import { usePrevious } from '@chakra-ui/react'
import { getPredictionDocPath, Prediction } from '@elan-twitch/shared'
import { truncateNumber } from '@elan-twitch/shared/utils'
import { useDocument } from '@elanmc/elan-react'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { FadeInText } from 'components/shared/FadeIn'
import 'fonts/Friz-Quadrata/style.css'
import 'fonts/Morpheus/style.css'
import { useProcessedPrediction } from 'hooks/useProcessedPrediction'
import { useTimeout } from 'hooks/useTimeout'
import { ChannelPoint } from 'icons/ChannelPoint'
import { Voter } from 'icons/Voter'
import { usePredictionConfig } from '../../Config/hooks'
import './styles.css'
import { WoWPredictionOutcome } from './WoWOutcome'

const spots = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

export const WoWPrediction: FC<{ data?: Prediction | null }> = ({
  data: _prediction,
}) => {
  const {
    broadcaster,
  } = useContext(BroadcasterContext)
  const predictionPath = useMemo(
    () => (broadcaster
      ? getPredictionDocPath(broadcaster.twitchId)
      : undefined),
    [broadcaster],
  )
  const { data } = useDocument<Prediction>(predictionPath)
  const prediction = useMemo(
    () => (_prediction !== undefined ? _prediction || undefined : data || undefined),
    [_prediction, data],
  )

  const { config } = usePredictionConfig()
  const { scale } = config

  const wasActive = usePrevious(prediction && !prediction?.winning_outcome_id)
  const showWinner = useTimeout(
    !!(wasActive && prediction?.winning_outcome_id),
    7000,
  )

  const displayed = useMemo(
    () => (!!prediction && (!prediction.ended_at || showWinner)
      ? prediction
      : undefined),
    [showWinner, prediction],
  )
  const { title = '', winning_outcome_id } = displayed || {}
  const { withRank, totalPoints, totalVoters } = useProcessedPrediction(displayed)

  const [barsHidden, setBarsHidden] = useState(false)
  useEffect(() => {
    if (prediction?.locked_at) {
      const t = setTimeout(() => {
        setBarsHidden(true)
      }, 3000)
      return () => clearTimeout(t)
    }
    setBarsHidden(false)
    return () => {}
  }, [prediction])

  return (
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'auto',
        overflow: 'visible',
        bottom: `${scale * 8}px`,
        right: `${scale * 8}px`,
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-end',
      }}
    >
      <FadeInText
        style={{
          position: 'relative',
          fontSize: `${scale * 0.6}rem`,
          paddingRight: `${scale * 0.5}rem`,
          display: 'flex',
          height: displayed ? `${scale * 12}px` : 0,
          transition: `all 500ms ease ${
            displayed ? 1000 + withRank.length * 600 : 0
          }ms`,
          alignItems: 'center',
          fontFamily: 'Friz Quadrata',
        }}
      >
        {displayed ? (
          <>
            <ChannelPoint
              style={{
                height: `${scale * 8}px`,
                marginRight: `${scale * 0.2}rem`,
                filter: 'drop-shadow(1px 1px 2px rgba(0,0,0,0.8))',
              }}
            />
            <span style={{ height: `${scale * 13}px` }}>
              {truncateNumber(totalPoints)}
            </span>
            <Voter
              style={{
                height: `${scale * 12}px`,
                marginLeft: `${scale * 0.3}rem`,
                filter: 'drop-shadow(1px 1px 2px rgba(0,0,0,1))',
              }}
            />
            <span
              style={{
                height: `${scale * 13}px`,
                position: 'relative',
                right: 0,
              }}
            >
              {truncateNumber(totalVoters)}
            </span>
          </>
        ) : null}
      </FadeInText>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          filter: 'drop-shadow(0 0 1px rgba(0,0,0,0.8))',
          paddingRight: `${scale * 0.5}rem`,
        }}
      >
        <FadeInText
          className='gradient-text'
          style={{
            fontSize: `${scale * 0.85}rem`,
            fontFamily: 'Morpheus',
            background:
              'linear-gradient(180deg, rgba(252,253,29,1) 50%, rgba(252,176,69,1) 100%)',
            filter: 'drop-shadow(0 0 2px rgba(0,0,0,1))',
            fontWeight: 600,
            textShadow: 'none',
          }}
        >
          {title}
        </FadeInText>
        <FadeInText
          className='gradient-text'
          style={{
            fontSize: `${scale * 0.85}rem`,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            opacity: winning_outcome_id ? 1 : 0,
            width: winning_outcome_id ? `${scale * 70}px` : 0,
            fontWeight: 600,
            fontFamily: 'Morpheus',
            filter: 'drop-shadow(0 0 2px rgba(0,0,0,1))',
            textShadow: 'none',
            background:
              'linear-gradient(180deg, rgba(252,253,29,1) 50%, rgba(252,176,69,1) 100%)',
          }}
        >
          - WINNER
        </FadeInText>
      </div>

      {spots.map((_, i) => (
        <WoWPredictionOutcome
          key={i}
          barsHidden={barsHidden && winning_outcome_id !== withRank[i]?.id}
          winner={winning_outcome_id}
          index={i}
          outcomes={withRank}
        />
      ))}
      <FadeInText
        style={{
          fontSize: `${scale * 0.5}rem`,
          paddingRight: `${scale * 0.5}rem`,
          color: 'white',
          fontFamily: 'Friz Quadrata',
          fontStyle: 'italic',
          opacity: !winning_outcome_id && displayed?.locked_at ? 0.8 : 0,
          // lineHeight: 2,
          height:
            !winning_outcome_id && displayed?.locked_at ? `${scale * 12}px` : 0,
        }}
      >
        {displayed?.locked_at ? 'PREDICTIONS LOCKED' : ''}
      </FadeInText>
    </div>
  )
}
