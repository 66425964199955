import {
  GetChannelChatEmotesResponse, GetThirdPartyAuthTokenArgs, GetThirdPartyAuthUrlArgs, GetTwitchBroadcasterAuthUrlArgs, HandleBroadcasterAuthRedirectArgs, TwitchBadge,
} from '@elan-twitch/shared'
import { Functions, httpsCallable } from 'firebase/functions'

export const getChannelChatEmotes = (functions: Functions) => httpsCallable<{channelName: string}, GetChannelChatEmotesResponse>(functions, 'getChannelChatEmotes')
export const getChannelBadges = (functions: Functions) => httpsCallable<{broadcasterId: string}, Record<TwitchBadge['set_id'], TwitchBadge>>(functions, 'getChannelBadges')
export const getThirdPartyAuthUrl = (functions: Functions) => httpsCallable<GetThirdPartyAuthUrlArgs, { url: string }>(
  functions,
  'getThirdPartyAuthUrl',
)
export const getThirdPartyAuthToken = (functions: Functions) => httpsCallable<GetThirdPartyAuthTokenArgs, {token: string, pathname: string}>(
  functions,
  'getThirdPartyAuthToken',
)

export const getTwitchBroadcasterAuthUrl = (functions: Functions) => httpsCallable<GetTwitchBroadcasterAuthUrlArgs, { url: string }>(
  functions,
  'getTwitchBroadcasterAuthUrl',
)

export const handleBroadcasterAuthRedirect = (functions: Functions) => httpsCallable<HandleBroadcasterAuthRedirectArgs, {pathname: string, error?: never} | {error: string, pathname?: never}>(functions, 'handleBroadcasterAuthRedirect')

export const deleteSubscription = (functions: Functions) => httpsCallable<{subscriptionId: string, broadcasterId: string, type: string}, {success: true}>(functions, 'deleteSubscription')
