import {
  Box, Button, Divider, Flex, Text, useColorModeValue,
} from '@chakra-ui/react'
import { getPollConfigPath, pollConfigField } from '@elan-twitch/shared'
import { ContentBox, Form, useFirebase } from '@elanmc/elan-react'
import { FORM_ERROR } from 'final-form'
import { doc, setDoc } from 'firebase/firestore'
import { useContext, useMemo } from 'react'
import { useTestPoll } from '../../../hooks/useTestPoll'
import { useUser } from '../../../store/useUser'
import { EnableSwitch } from '../../shared/EnableSwitch'
import { BroadcasterContext } from '../Broadcasters/context'
import { OWPollView } from '../Poll/OWPoll'
import { usePollConfig } from './hooks'

export const PollConfig = () => {
  const { broadcaster } = useContext(BroadcasterContext)
  const { user, isAdmin, channelAdminOf } = useUser()
  const { db } = useFirebase()

  const { config, path } = usePollConfig()
  const { enabled } = config || {}

  const configDoc = useMemo(() => (path ? doc(db, path) : null), [path, db])

  const { _id: broadcasterId } = broadcaster || {}

  const isAuthorized = useMemo(
    () => !!(user && (isAdmin || (broadcasterId && channelAdminOf?.includes(broadcasterId)))),
    [isAdmin, broadcasterId, channelAdminOf, user],
  )

  const formBg = useColorModeValue('blackAlpha.400', 'whiteAlpha.500')
  const { data: testPoll, restart: restartPoll } = useTestPoll(isAuthorized)
  return (
    <Flex w='100%' h='100%'>
      <Flex bg={formBg} p={4} boxShadow='3px 0 4px #00000077' h='100%'>
        <Box maxH='100%'>
          <ContentBox flexFlow='column' width={325} maxH='100%' p={0} overflowY='auto' overflowX='hidden'>
            <Flex px={2} w='100%' align='center'>
              <Text flex={1} py={2} fontSize='lg' opacity={0.8}>
                POLLS OVERLAY
              </Text>
              <EnableSwitch enabled={enabled} docRef={configDoc} propPath='enabled' />
            </Flex>
            <Divider />
            <Box opacity={config.enabled ? 1 : 0.5} w='100%'>
              <Form
                buttonText='SAVE'
                field={pollConfigField}
                value={config}
                onSubmit={async (d) => {
                  if (!user || !configDoc) return { [FORM_ERROR]: 'Not authed' }
                  return setDoc(doc(db, getPollConfigPath(broadcasterId)), d, { merge: true })
                    .then(() => undefined)
                    .catch((err) => {
                      console.error(err)
                      return { [FORM_ERROR]: 'Error saving' }
                    })
                }}
              />
              <Divider />
              <Flex py={2} px={4}>
                <Button variant='outline' size='sm' onClick={() => restartPoll()}>
                  Preview - restart
                </Button>
              </Flex>
            </Box>
          </ContentBox>
        </Box>
      </Flex>
      <Flex position='relative' h='100%' flex={1}>
        <OWPollView data={testPoll} />
      </Flex>
    </Flex>
  )
}
