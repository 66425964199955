import {
  Box,
  HStack,
  IconButton,
  Image, Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  Broadcaster,
} from '@elan-twitch/shared'
import {
  Field, FieldMap, Form, useFirebase, WithId,
} from '@elanmc/elan-react'
import { CloseButton } from 'components/shared/Buttons'
import { ShadowText } from 'components/shared/ShadowText'
import { AnyObject } from 'final-form'
import { handlePointsAuctionSubmit } from './utils'

// const typeField: SelectField = {
//   _type: 'select',
//   placeholder: 'Type',
//   options: [
//     { label: 'Automatic Auction (countdown timer)', id: 'automatic' },
//     { label: 'Manual Auction (button to end)', id: 'manual' },
//   ],
// }

const titleField: Field = {
  _type: 'text',
  placeholder: 'Title',
}

const countdownTimeoutField: Field = {
  _type: 'number',
  // type: 'slider',
  // step: 1,
  min: 5,
  max: 60,
  placeholder: 'Countdown Timeout (seconds)',
}

const bidStartPriceField: Field = {
  _type: 'number',
  min: 0,
  max: 100000,
  placeholder: 'Start Bidding At (Points)',
}

const bidMinIncrementField: Field = {
  _type: 'number',
  min: 1,
  max: 100000,
  placeholder: 'Minimum Bid Increment (Points)',
}

const pointsAuctionField: FieldMap = {
  name: 'Points Auction',
  children: {
    // type: typeField,
    title: titleField,
    bid_start_price: bidStartPriceField,
    timeout_seconds: countdownTimeoutField,
    bid_min_increment: bidMinIncrementField,
  },
}
const initialValues: AnyObject = {
  title: '',
  bid_start_price: 1000,
  timeout_seconds: 15,
  bid_min_increment: 100,
}

export const CreatePointsAuction = ({
  enabled,
  broadcaster,
  onSwitch,
}: {
  enabled: boolean
  broadcaster: WithId<Broadcaster>
  onSwitch: (mode: 'load' | null) => void
}) => {
  const { db } = useFirebase()
  return (
  <VStack
    w='500px'
    opacity={enabled ? 1 : 0}
    transition='all 400ms'
    pointerEvents={enabled ? 'auto' : 'none'}
  >
    <VStack w='100%' p={4} borderRadius={5} boxShadow='md' bg='#323232'>
      <HStack px={2} w='100%'>
        <ShadowText style={{ flex: 1, fontFamily: 'Bebas Neue', fontSize: '1.4rem' }} >
          New Auction
        </ShadowText>
        <Tooltip
          bg='blackAlpha.700'
          placement='top'
          hasArrow
          fontSize='lg'
          fontFamily='Bebas Neue'
          label='LOAD SAVED AUCTION'
        >
          <IconButton
            aria-label='Load'
            ml='auto'
            fontFamily='Bebas Neue'
            onClick={() => onSwitch('load')}
            size='sm'
            bg='whiteAlpha.500'
            _hover={{ bg: 'whiteAlpha.600' }}
            opacity={0.8}
            icon={<Image filter='invert(100%)' opacity={0.6} src='/assets/images/history.svg' />}
            fontSize='1rem'
          />
        </Tooltip>
        <CloseButton onClick={() => onSwitch(null)} />
      </HStack>
      <Box w='100%' maxH='600px' overflowY='auto'>
        {enabled ? (
          <Form
            value={initialValues}
            buttonText='START'
            field={pointsAuctionField}
            onSubmit={(v) => handlePointsAuctionSubmit(db, broadcaster, v)}
          />
        ) : null}
      </Box>
    </VStack>
  </VStack>
  )
}
