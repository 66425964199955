import { MovieListMovie } from '@elan-twitch/shared'
import { useFirebase, useQuery } from '@elanmc/elan-react'
import {
  limit as limitTo, orderBy, query, where,
} from 'firebase/firestore'
import { useMemo } from 'react'
import { getBroadcasterMovieListCollection } from '../../../backend/db'

export const useTopMovies = (broadcasterId: string | null) => {
  const { db } = useFirebase()
  const dbQuery = useMemo(
    () => (broadcasterId
      ? query(
        getBroadcasterMovieListCollection(db, broadcasterId),
        orderBy('vote_score', 'desc'),
        where('watched', '==', false),
        limitTo(20),
      )
      : null),
    [broadcasterId, db],
  )

  return useQuery<MovieListMovie>(dbQuery)
}
