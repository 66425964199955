import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { getPredictionConfigPath, predictionConfigField } from '@elan-twitch/shared'
import { Form, useFirebase } from '@elanmc/elan-react'
import { FORM_ERROR } from 'final-form'
import { doc, setDoc } from 'firebase/firestore'
import { useContext, useMemo } from 'react'
import { useTestPrediction } from '../../../hooks/useTestPrediction'
import { useUser } from '../../../store/useUser'
import { ContentBox } from '../../shared/ContentBox'
import { EnableSwitch } from '../../shared/EnableSwitch'
import { BroadcasterContext } from '../Broadcasters/context'
import { PredictionView } from '../Prediction/PredictionView'
import { usePredictionConfig } from './hooks'

export const PredictionConfig = () => {
  const { broadcaster } = useContext(BroadcasterContext)
  const { user, isAdmin, channelAdminOf } = useUser()

  const { db } = useFirebase()
  const { config, path } = usePredictionConfig()
  const { enabled } = config || {}
  const configDoc = useMemo(() => (path ? doc(db, path) : null), [path, db])

  const { _id: broadcasterId } = broadcaster || {}
  const isAuthorized = useMemo(
    () => !!(user && (isAdmin || (broadcasterId && channelAdminOf?.includes(broadcasterId)))),
    [isAdmin, broadcasterId, channelAdminOf, user],
  )
  const formBg = useColorModeValue('blackAlpha.400', 'whiteAlpha.500')
  const {
    data: testPrediction,
    toggleShowWinner,
    restart: restartPrediction,
    showWinner,
    numOutcomes,
    setNumOutcomes,
  } = useTestPrediction(isAuthorized)
  return (
    <Flex h='100%' w='100%'>
      <Flex bg={formBg} boxShadow='3px 0 4px #00000077' p={4} h='100%'>
        <Box maxH='100%'>
          <ContentBox p={0} overflowY='auto' flexFlow='column' maxH='100%' width={355}>
            <Flex px={2} w='100%' align='center'>
              <Text flex={1} py={2} fontSize='lg' opacity={0.8}>
                PREDICTIONS OVERLAY
              </Text>
              <EnableSwitch enabled={enabled} docRef={configDoc} propPath='enabled' />
            </Flex>
            <Divider />
            <Box opacity={config.enabled ? 1 : 0.5} w='100%'>
              <Form
                buttonText='SAVE'
                field={predictionConfigField}
                value={config}
                onSubmit={async (d) => {
                  if (!user || !configDoc) return { [FORM_ERROR]: 'Not authed' }
                  return setDoc(doc(db, getPredictionConfigPath(broadcasterId)), d, { merge: true })
                    .then(() => undefined)
                    .catch((err) => {
                      console.error(err)
                      return { [FORM_ERROR]: 'Error saving' }
                    })
                }}
              />
            </Box>
            <Divider />
            <VStack py={2} px={4} borderTop='1px solid rgba(0,0,0,0.3)'>
              <Text>Preview - outcomes</Text>
              <NumberInput size='sm' onChange={(_, v) => setNumOutcomes(v)} value={numOutcomes} min={2} max={10}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper color='whiteAlpha.700' />
                  <NumberDecrementStepper color='whiteAlpha.700' />
                </NumberInputStepper>
              </NumberInput>
              <Checkbox mt={2} isChecked={showWinner} onChange={toggleShowWinner}>
                Preview - show winner
              </Checkbox>
              <Button mt={2} variant='outline' size='sm' onClick={() => restartPrediction()}>
                Preview - restart
              </Button>
            </VStack>
          </ContentBox>
        </Box>
      </Flex>
      <Flex position='relative' h='100%' flex={1}>
        <PredictionView data={testPrediction} />
      </Flex>
    </Flex>
  )
}
