import {
  EmoteResponse,
  GetChannelChatEmotesResponse,
  getSevenTvEmoteUrl,
  getTwitchEmoteUrl,
  SevenTvEmoteOptions,
  TwitchEmoteOptions,
} from '@elan-twitch/shared'
import { useFirebase } from '@elanmc/elan-react'
import { getChannelChatEmotes } from 'backend/functions'
import {
  useEffect, useMemo, useRef, useState,
} from 'react'

const twitchEmoteOptions: TwitchEmoteOptions = {
  format: 'default',
  scale: '1.0',
  theme_mode: 'light',
}
const sevenTvEmoteOptions: SevenTvEmoteOptions = {
  size: '1x',
  format: 'webp',
}
export type UseChatEmotesConfig = {
  size?: 1 | 2 | 3
}
const parseTwitchEmotes = (
  emotes: Record<string, EmoteResponse>,
  config?: UseChatEmotesConfig,
) => Object.entries(emotes).reduce(
  (acc, [emoteId, { name: emoteName }]) => ({
    ...acc,
    [emoteName]: getTwitchEmoteUrl(emoteId, {
      ...twitchEmoteOptions,
      scale: config?.size ? `${config.size}.0` : '1.0',
    }),
  }),
    {} as Record<string, string>,
)

const parseSevenTvEmotes = (
  emotes: Record<string, EmoteResponse>,
  config?: UseChatEmotesConfig,
) => Object.entries(emotes).reduce(
  (acc, [emoteId, { name: emoteName }]) => ({
    ...acc,
    [emoteName]: getSevenTvEmoteUrl(emoteId, {
      ...sevenTvEmoteOptions,
      size: config?.size ? `${config.size}x` : '1x',
    }),
  }),
    {} as Record<string, string>,
)

export const useChatEmotes = (
  broadcasterId?: string,
  config?: UseChatEmotesConfig,
) => {
  const [emoteIds, setEmoteIds] = useState<GetChannelChatEmotesResponse | null>(
    null,
  )
  const emotes = useMemo(
    () => ({
      ...parseTwitchEmotes(
        {
          ...emoteIds?.twitch?.globalEmotes,
          ...emoteIds?.twitch?.channelEmotes,
        },
        config,
      ),
      ...parseSevenTvEmotes(
        {
          ...emoteIds?.sevenTv?.globalEmotes,
          ...emoteIds?.sevenTv?.channelEmotes,
        },
        config,
      ),
    }),
    [emoteIds, config],
  )

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const previousBroadcasterId = useRef<string | null>(null)
  const { functions } = useFirebase()
  useEffect(() => {
    if (!broadcasterId) return
    if (previousBroadcasterId.current === broadcasterId) return
    setIsLoading(true)
    previousBroadcasterId.current = broadcasterId
    getChannelChatEmotes(functions)({ channelName: broadcasterId })
      .then((res) => {
        setIsLoading(false)
        setEmoteIds(res.data)
      })
      .catch((err: any) => {
        setIsLoading(false)
        setError(err.message || 'Error fetching emotes')
      })
  }, [broadcasterId, functions])

  return { emotes, error, isLoading }
}
