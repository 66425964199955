import {
  Box, Flex, Heading, Text,
} from '@chakra-ui/react'
import { useBorderColor } from '@elanmc/elan-react'
import { ViewAsProvider, ViewAsToggle } from '../../../contexts/ViewAs'
import { useIsChannelAdmin } from '../../../hooks/useIsChannelAdmin'
import { NymnPage } from '../NymnPage'
import { GameJamVoting } from './GameJamVoting'

const AbsoluteViewAsToggle = () => (
    <Box position='absolute' top={2} right={2}>
      <ViewAsToggle/>
    </Box>
)

export const GameJamPage = () => {
  const borderColor = useBorderColor()
  const isAdmin = useIsChannelAdmin()
  return (
    <ViewAsProvider>
      <NymnPage>
        <Flex h='100%' align='center' flexFlow='column' w='100%'>
          <Flex
            position='relative'
            bg='blackAlpha.100'
            flexFlow='column'
            align='center'
            p={4}
            w='100%'
            borderBottom={`1px solid ${borderColor}`}
          >
            <Heading color='white' textShadow='1px 1px 3px #00000066'>
              NymN's Game Jam: Horror Edition
            </Heading>
            <Text color='white' fontSize='xl' opacity={0.8}>
              A Community Contest for Aspiring Game Developers
            </Text>
            {isAdmin ? <AbsoluteViewAsToggle /> : null}
          </Flex>
          <Flex align='center' flex={1} minH='0' overflowY='auto' flexFlow='column' py={4} w='100%'>
            <GameJamVoting />
          </Flex>
        </Flex>
      </NymnPage>
    </ViewAsProvider>
  )
}
