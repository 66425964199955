import { DefaultSelectOption, FieldMap, SelectField } from '@elanmc/elan-react'
import { twitchEvents, twitchUserScopes } from '../../constants/twitch'
import { textureField } from '../../fields/colors'
import { pollConfigField } from '../poll/constants'
import { Widget } from '../types'
import { PredictionWidgetConfig } from './types'

export const fallbackPredictionConfig: PredictionWidgetConfig = {
  enabled: false,
  barOpacity: 1,
  bgOpacity: 0.5,
  bgVisible: true,
  darkMode: false,
  gradient: 'blue',
  isPortrait: true,
  scale: 1.6,
  swapColors: false,
  texture: null,
  version: 'classic',
}

const predictionVersionOptions: DefaultSelectOption[] = [
  { id: 'classic', text: 'Classic' },
  { id: 'ow', text: 'Overwatch' },
  { id: 'rl', text: 'Rocket League' },
  { id: 'wow', text: 'WoW' },
]

const predictionVersionField: SelectField = {
  _type: 'select',
  placeholder: 'Version',
  options: predictionVersionOptions.map((o) => o.id),
  renderOption: (o, colorMode) => (
    <div
      style={{
        alignItems: 'center',
        padding: '0.25rem 0.5rem',
        display: 'flex',
        borderRadius: 6,
        minHeight: '24px',
        transition: 'background 500ms',
        color: 'white',
        textShadow: '1px 1px 3px #00000077',
        background: colorMode === 'dark' ? '#ffffff33' : '#00000033',
        width: '100%',
      }}
    >
      {predictionVersionOptions.find((opt) => opt.id === o)?.text || 'None'}
    </div>
  ),
}

export const predictionConfigField: FieldMap = {
  children: {
    version: predictionVersionField,
    ...pollConfigField.children,
    texture: textureField,
  },
}

export const predictionOverlayWidget: Widget = {
  id: 'prediction-overlay',
  name: 'Prediction Overlay',
  configField: predictionConfigField,
  requiredScopes: [twitchUserScopes.CHANNEL_READ_PREDICTIONS],
  eventTypes: [
    twitchEvents.CHANNEL_PREDICTION_BEGIN,
    twitchEvents.CHANNEL_PREDICTION_PROGRESS,
    twitchEvents.CHANNEL_PREDICTION_LOCK,
    twitchEvents.CHANNEL_PREDICTION_END,
  ],
}
