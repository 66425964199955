import {
  Box, Center, Flex, VStack,
} from '@chakra-ui/react'
import {
  combineValidators,
  recordToArray,
  validatePositiveNumber,
  validateRequired,
} from '@elan-twitch/shared'
import { Editable, NumberField, useFirebase } from '@elanmc/elan-react'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { CollapseCenter } from 'components/shared/CollapseCenter'
import { EditorWrapper } from 'components/shared/EditorWrapper'
import { LoadingSwitch } from 'components/shared/LoadingSwitch'
import { useContext, useMemo, useState } from 'react'
import { SquareShadowTextButton } from '../../../shared/Buttons'
import { defaultEgg } from '../../../Widgets/RandomDrops/Eggs/constants'
import { EggEdit } from './EggEdit'
import { EggSelectButton } from './EggSelectButton'
import {
  disableEggs, enableEggs, updateEggsDropPeriod, updateEggsExpirationTime,
} from './utils'

const dropPeriodField: NumberField = {
  _type: 'number',
  min: 10,
  max: 60 * 8,
  step: 1,
  placeholder: 'Drop Period (minutes)',
  validate: combineValidators(validateRequired, validatePositiveNumber),
}

const expirationTimeField: NumberField = {
  _type: 'number',
  min: 1,
  max: 60,
  step: 1,
  placeholder: 'Eggs expire after (minutes)',
  validate: combineValidators(validateRequired, validatePositiveNumber),
}

export const EggsConfigEdit = ({ onBack, active }: { onBack: () => void; active: boolean }) => {
  // -1 reserved for new egg
  const { db } = useFirebase()
  const [selectedEggId, setSelectedEggKey] = useState<string | null>(null)

  const { broadcaster } = useContext(BroadcasterContext)
  const { eggsConfig } = broadcaster?.pointsBankAccountConfig?.addOns?.randomDrops?.droppedItems || {}
  const {
    eggs, dropPeriod, enabled, expirationTime,
  } = eggsConfig || {}
  const eggsArr = useMemo(() => recordToArray(eggs || {}).sort((a, b) => a.rank - b.rank), [eggs])

  return (
    <EditorWrapper
      title='eggs'
      onBack={selectedEggId === null ? onBack : undefined}
      boxProps={{ p: 0 }}
      active={active}
    >
      <CollapseCenter unmountOnExit in={selectedEggId !== null}>
        <EggEdit
          id={selectedEggId || ''}
          initialValue={selectedEggId ? eggs?.[selectedEggId] : { ...defaultEgg, rank: eggsArr.length }}
          onBack={() => setSelectedEggKey(null)}
        />
      </CollapseCenter>
      <CollapseCenter unmountOnExit in={selectedEggId === null}>
        <VStack spacing={0} w='100%'>
          <Flex w='100%' bg='blackAlpha.300' borderBottom='1px solid #ffffff77'>
            <Center gap={2} py={2} px={2} flexFlow='column' w='100%' flex={1} pr={1}>
              <Center w='100%'>
                <LoadingSwitch
                  label={enabled ? 'Enabled' : 'Disabled'}
                  value={!!enabled}
                  onChange={enabled ? () => disableEggs(db, broadcaster._id) : () => enableEggs(db, broadcaster._id)}
                />
              </Center>
              <Box w='100%'>
                <Editable
                  // size='sm'
                  field={dropPeriodField}
                  // inputStackProps={{ width: '100%' }}
                  // horizontal
                  value={dropPeriod === undefined ? undefined : dropPeriod / 60}
                  onSubmit={(v) => updateEggsDropPeriod(db, broadcaster._id, v ? v * 60 : undefined)}
                />
              </Box>
              <Box w='100%'>
                <Editable
                  // size='sm'
                  field={expirationTimeField}
                  // // inputStackProps={{ width: '100%' }}
                  // horizontal
                  value={expirationTime === undefined ? 5 : expirationTime / 60}
                  onSubmit={(v) => updateEggsExpirationTime(db, broadcaster._id, v ? v * 60 : undefined)}
                />
              </Box>
            </Center>
          </Flex>
          <Flex bg='' py={1} flexFlow='row wrap' w='100%' justify='center' align='center'>
            {eggsArr.map((egg) => (
              <Box key={egg._id} p={2}>
                <EggSelectButton onOpen={() => setSelectedEggKey(egg._id)} egg={egg} />
              </Box>
            ))}
            <Box p={2}>
              <SquareShadowTextButton width='180px' onClick={() => setSelectedEggKey(`${Date.now()}`)}>
                + CREATE EGG
              </SquareShadowTextButton>
            </Box>
          </Flex>
        </VStack>
      </CollapseCenter>
    </EditorWrapper>
  )
}
