import { BooleanField, Editable } from '@elanmc/elan-react'
import { DocumentReference, setDoc } from 'firebase/firestore'
import { useCallback, useMemo } from 'react'

const enabledField: BooleanField = {
  _type: 'boolean',
  placeholder: 'Enabled',
  type: 'switch',
}
export const EnableSwitch = ({
  enabled,
  propPath,
  docRef,
}: {
  enabled?: boolean
  docRef: DocumentReference | null
  propPath: string
}) => {
  const field = useMemo(() => ({ ...enabledField, placeholder: enabled ? 'Enabled' : 'Disabled' }), [enabled])
  const onSubmit = useCallback(async (v: boolean | undefined) => {
    if (!docRef) throw new Error('docRef is required')
    await setDoc(docRef, { [propPath]: !!v }, { merge: true })
  }, [docRef, propPath])
  return (<Editable<BooleanField> value={enabled} field={field} onSubmit={onSubmit} />)
}
