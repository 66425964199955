import { Flex, HStack, Text } from '@chakra-ui/react'
import { DroppedItemType } from '@elan-twitch/shared'
import { useFirebase } from '@elanmc/elan-react'
import { BroadcasterContext } from 'components/App/Broadcasters/context'
import { CollapseCenter } from 'components/shared/CollapseCenter'
import { DashboardContent } from 'components/shared/DashboardContent'
import { IsActiveButton } from 'components/shared/IsActiveButton'
import { useCallback, useContext, useState } from 'react'
import { SquareShadowTextButton } from '../../../shared/Buttons'
import { useBroadcasterDropsActive } from '../../../Widgets/RandomDrops/hooks'
import { EggsConfigEdit } from './EggsConfigEdit'
import {
  disableRandomDrops, enableRandomDrops, startDropsFunction, stopDropsFunction,
} from './utils'

// name: string // eg 'common', 'uncommon', 'rare', 'epic', 'legendary'
// rollThreshold: number // roll required to  get this tier (out of 100)
// price: number
// priceVariance?: number // can be used to randomize price +/- this number
// color: string
// emissiveIntensity?: number // optional glow amount

// const defaultEgg: Egg = {
//   color: { r: 100, g: 150, b: 220 },
//   rank: 0,
//   name: '',
//   mass: 1,
//   price: 1000,
//   rollThreshold: 25,
// }
const RandomDropsConfigForm = () => {
  const [editedItem, setEditedItem] = useState<DroppedItemType | null>(null)
  const { db, functions } = useFirebase()
  const { broadcaster } = useContext(BroadcasterContext)
  const onItemEdit = useCallback((item: DroppedItemType | null) => {
    setEditedItem(item)
  }, [])
  const dropsStatus = useBroadcasterDropsActive()
  return (
    <DashboardContent width={500} boxProps={{ maxW: '800px', width: '100%' }}>
      <CollapseCenter unmountOnExit in={!editedItem}>
        <Flex w='100%' direction='column'>
          <HStack spacing={0} px={2} pb={3} w='100%'>
            <Text opacity={0.8} fontWeight={600} fontSize='md'>RANDOM DROPS</Text>
            <Flex py={1} px={2} ml='auto' >
              <IsActiveButton
                disable={async () => {
                  await stopDropsFunction(functions)({ broadcasterId: broadcaster._id })
                  await disableRandomDrops(db, broadcaster._id)
                }}
                enable={async () => {
                  await startDropsFunction(functions)({ broadcasterId: broadcaster._id })
                  await enableRandomDrops(db, broadcaster._id)
                }}
                initStatus={dropsStatus}
              />
            </Flex>
            {/* <Flex flex={1} justify='flex-end'>
              <LoadingSwitch
                label={enabled ? 'Enabled' : 'Disabled'}
                value={!!enabled}
                onChange={
                  enabled ? () => disableRandomDrops(broadcaster._id) : () => enableRandomDrops(broadcaster._id)
                }
              />
            </Flex> */}
          </HStack>
          <Flex
            justify='center'
            flexFlow='row wrap'
            w='100%'
            borderRadius={4}
            p={3}
            boxShadow='inset 0 0 4px black'
            bg='blackAlpha.300'
          >
            <SquareShadowTextButton
              onClick={() => {
                onItemEdit('egg')
              }}
            >
              EGGS
            </SquareShadowTextButton>
          </Flex>
        </Flex>
      </CollapseCenter>
      <CollapseCenter in={editedItem === 'egg'} unmountOnExit>
        <EggsConfigEdit onBack={() => setEditedItem(null)} active={editedItem === 'egg'} />
      </CollapseCenter>
    </DashboardContent>
  )
}

export const RandomDropsConfig = () => <RandomDropsConfigForm />
